import {
  GET_MEMBERSHIP_HOME_MAJOR_SUCCEED,
  GET_MEMBERSHIP_HOME_VAUCHER_SUCCEED,
  GET_MEMBERSHIP_HOME_MG_SUCCEED,
  GET_MEMBERSHIP_HOME_SG_SUCCEED,
  GET_MEMBERSHIP_HOME_WG_SUCCEED,
  GET_MEMBERSHIP_HOME_STATIC_SUCCEED,
  GET_MEMBERSHIP_HOME_WINNING_CHANCE_SUCCEED,
  CREATE_VOUCHER_CODE_SUCCEED,
  CREATE_VOUCHER_CODE,
  CREATE_VOUCHER_CODE_FAILED,
  GET_MEMBERSHIP_HOME_REFERENCE_COUNT,
  RENEW_MEMBERSHIP,
  RENEW_MEMBERSHIP_SUCCEED,
  RENEW_MEMBERSHIP_FAILED,
} from '../../actions'

const membershipHome = (state = {
  majors: [],
  vauchers: [],
  meetGreets: [],
  studioGuides: [],
  welcomeGifts: null,
  winningChance: [],
  content: {},
  loadingVouchers: {},
  renewMembershipState: {
    isLoading: false,
    error: null,
  },
}, action:any) => {
  switch (action.type) {
    case GET_MEMBERSHIP_HOME_MAJOR_SUCCEED:
      return {
        ...state,
        majors: action.payload
      }
    case GET_MEMBERSHIP_HOME_VAUCHER_SUCCEED:
      return {
        ...state,
        vauchers: action.payload,
      }
    case GET_MEMBERSHIP_HOME_MG_SUCCEED:
      return {
        ...state,
        meetGreets: action.payload,
      }
    case GET_MEMBERSHIP_HOME_WG_SUCCEED:
      return {
        ...state,
        welcomeGifts: action.payload,
      }
    case GET_MEMBERSHIP_HOME_SG_SUCCEED:
      return {
        ...state,
        studioGuides: action.payload,
      }
    case GET_MEMBERSHIP_HOME_STATIC_SUCCEED:
        return {
          ...state,
          content: action.payload,
        }
    case GET_MEMBERSHIP_HOME_WINNING_CHANCE_SUCCEED:
        return {
          ...state,
          winningChance: action.payload,
        }
    case CREATE_VOUCHER_CODE:
        return {
          ...state,
          loadingVouchers: {
            [action.id]: true
          }
        }
    case CREATE_VOUCHER_CODE_SUCCEED:
    case CREATE_VOUCHER_CODE_FAILED:
        return {
          ...state,
          loadingVouchers: {
            ...state.loadingVouchers,
            [action.id]: false,
          }
        }
    case GET_MEMBERSHIP_HOME_REFERENCE_COUNT:
      return {
        ...state,
        referenceCount: action.payload,
      }
    case RENEW_MEMBERSHIP:
      return {
        ...state,
        renewMembershipState: {
          isLoading: true,
          error: null,
        },
      };
    case RENEW_MEMBERSHIP_SUCCEED:
      return {
        ...state,
        renewMembershipState: {
          isLoading: false,
          error: null,
        },
      };
    case RENEW_MEMBERSHIP_FAILED:
      return {
        ...state,
        renewMembershipState: {
          isLoading: false,
          error: action.payload,
        },
      };
    default:
      return state
  }
}

export default membershipHome;
