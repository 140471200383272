import * as React from 'react';


const Toastr = ({onClose, title, text, isError}: any) => {
  return (
    <div className={`eventNotification ${isError ? 'eventNotificationError' : ''}`} >
      <div className="eventNotificationHeader d-flex">
          <div className="headerTitle">
              {title}
          </div>
          <div className="eventClose">
              <button onClick={ onClose } className="closeModal" />                                
          </div>
      </div>
      <div className="eventNotificationContent" dangerouslySetInnerHTML={{ __html: text}}>
          {/* <div className="contentItem">Deine Bestelle Nummer lautet: 498896 </div>
          <div className="contentItem">Wir haben dir einen E-Mail geschickt mit alle wichtigen infos.</div>
          <div className="contentItem"> Als dankeschön zur Mitgliedschaft kiregst du einen Membership goody. Hier siehst du was für ein Exklusives Geschenk du hast.</div> */}
      </div>
  </div>
  );
};

export default Toastr;
