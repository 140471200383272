import * as React from 'react';
import moment from 'moment-with-locales-es6';

const MembershipCard = (props: any) => {
  const { product, chosen, included } = props;
  return (
    <div className="checkoutMembership">
      <div className="checkoutItemRow d-flex">
          <div className="productLogo">
            <img src={product.images && product.images[0]} alt="" />
          </div>
          <div className="checkoutTwo d-flex">
              <div className="aboutProduct">
                  <div className="productName">
                      {product.title}
                  </div>
                  <div className="checkoutProductText">
                      {product.location || "-"} <br />
                      { product.product_start_date && moment(product.product_start_date).locale('de').format("DD. MMMM YYYY")}
              </div>
              </div>
              <div className="priceToNow d-flex">
                  <div className="productInclude">
                      <span>{included}</span>
                  </div>
                  <div className="productFavorite">
                      <button className="addFavorite">
                          <span>{chosen}</span>
                      </button>
                  </div>
              </div>
          </div>
      </div>
  </div>
  );
};

export default MembershipCard;
