import {
  GET_MAJOR_BENEFITS_STATIC_CONTENT_SUCCEED,
  GET_MAJOR_BENEFITS_STATIC_CONTENT,
  GET_BENEFIT_PRODUCTS,
  GET_BENEFIT_PRODUCTS_SUCCEED,
  GET_LANDING_BENEFITS,
  GET_LANDING_BENEFITS_SUCCEED,
  GET_LANDING_STATIC_CONTENT,
  GET_LANDING_STATIC_CONTENT_SUCCEED,
  GET_LANDING_BENEFIT,
  GET_LANDING_BENEFIT_SUCCEED,
  GET_PRICE_PLAN,
  GET_PRICE_PLAN_SUCCEED,
  GENERATE_INVOICE,
  GENERATE_INVOICE_SUCCEED,
  GENERATE_INVOICE_FAILED,
  STORE_USER,
  STORE_USER_SUCCEED,
  STORE_USER_FAILED,
  SET_SELECTED_PRODUCT,
  GET_TEMPLATE_VARIABLES,
  GET_TEMPLATE_VARIABLES_SUCCEED,
  GET_ME,
  GET_ME_SUCCEED,
  REDIRECT,
  GET_MEMBERSHIP_HOME_MAJOR,
  GET_MEMBERSHIP_HOME_MAJOR_SUCCEED,
  GET_MEMBERSHIP_HOME_MAJOR_FAILED,
  GET_MEMBERSHIP_HOME_VAUCHER,
  GET_MEMBERSHIP_HOME_VAUCHER_SUCCEED,
  GET_MEMBERSHIP_HOME_VAUCHER_FAILED,
  GET_MEMBERSHIP_HOME_MG,
  GET_MEMBERSHIP_HOME_MG_SUCCEED,
  GET_MEMBERSHIP_HOME_MG_FAILED,
  GET_MEMBERSHIP_HOME_SG,
  GET_MEMBERSHIP_HOME_SG_SUCCEED,
  GET_MEMBERSHIP_HOME_SG_FAILED,
  CREATE_VOUCHER_CODE,
  CREATE_VOUCHER_CODE_SUCCEED,
  CREATE_VOUCHER_CODE_FAILED,
  CHANGE_MAJOR_BENEFIT,
  CHANGE_MAJOR_BENEFIT_SUCCEED,
  CHANGE_MAJOR_BENEFIT_FAILED,
  ADD_MEMBERSHIP,
  DELETE_MEMBERSHIP,
  GET_MEMBERSHIP_HOME_WG,
  GET_MEMBERSHIP_HOME_WG_SUCCEED,
  GET_PARTNERS,
  GET_PARTNERS_SUCCEED,
  GET_FAQ,
  GET_FAQ_SUCCEED,
  GET_LOGIN_STATIC_CONTENT,
  GET_LOGIN_STATIC_CONTENT_SUCCEED,
  GET_CHECKOUT_STATIC_CONTENT,
  GET_CHECKOUT_STATIC_CONTENT_SUCCEED,
  GET_MEMBERSHIP_HOME,
  GET_MEMBERSHIP_HOME_SUCCEED,
  HIDE_SUCCESSFUL_PAYMENT_MESSAGE,
  GET_MEMBERSHIP_HOME_STATIC,
  GET_MEMBERSHIP_HOME_STATIC_SUCCEED,
  GET_MEMBERSHIP_HOME_WINNING_CHANCE_SUCCEED,
  GENERATE_GIFT_INVOICE,
  GENERATE_GIFT_INVOICE_SUCCEED,
  GENERATE_GIFT_INVOICE_FAILED,
  CHECK_COUPON_CODE,
  CHECK_COUPON_CODE_SUCCEED,
  CHECK_COUPON_CODE_FAILED,
  ACTIVATE_MEMBERSHIP,
  ACTIVATE_MEMBERSHIP_SUCCEED,
  ACTIVATE_MEMBERSHIP_FAILED,
  REDIRECT_TO_PAYMENT,
  TOGGLE_GIFT_TOASTR,
  RESET_CHECKING_COUPON_CODE,
  GET_DELIVERY_PRICE,
  SET_DELIVERY_PRICE,
  GET_MEMBERSHIP_HOME_REFERENCE_COUNT,
  RENEW_MEMBERSHIP,
  RENEW_MEMBERSHIP_SUCCEED,
  RENEW_MEMBERSHIP_FAILED,
  UPDATE_IS_LOGGED_IN,
} from './actions';

// CHOOSE MAJOR BENEFITS PAGE
export const getMajorBenefitsStaticContent = () => ({
  type: GET_MAJOR_BENEFITS_STATIC_CONTENT
});

export const getMajorBenefitsStaticContentSucceed = (payload: any) => ({
  type: GET_MAJOR_BENEFITS_STATIC_CONTENT_SUCCEED,
  payload,
})

export const getBenefitProducts = () => ({
  type: GET_BENEFIT_PRODUCTS
});

export const getBenefitProductsSucceed = (payload: any) => ({
  type: GET_BENEFIT_PRODUCTS_SUCCEED,
  payload
})

export const setSelectedBenefitProduct = (product: any) => ({
  type: SET_SELECTED_PRODUCT,
  product,
})
// LANDING PAGE
export const getLandingBenefits = () => ({
  type: GET_LANDING_BENEFITS,
});


export const getLandingBenefitsSucceed = (payload: any) => ({
  type: GET_LANDING_BENEFITS_SUCCEED,
  payload,
})

export const getLandingStaticContent = () => ({
  type: GET_LANDING_STATIC_CONTENT
})

export const getLandingStaticContentSucceed = (payload: any) => ({
  type: GET_LANDING_STATIC_CONTENT_SUCCEED,
  payload
})


export const getLandingBenefit = (id: number) => ({
  type: GET_LANDING_BENEFIT,
  id
})


export const getLandingBenefitSucceed = (payload: any) => ({
  type: GET_LANDING_BENEFIT_SUCCEED,
  payload,
})


export const getTemplateVariables = () => ({
  type: GET_TEMPLATE_VARIABLES,
});

export const getTemplateVariablesSucceed = (payload: any) => ({
  type: GET_TEMPLATE_VARIABLES_SUCCEED,
  payload,
});

export const getLandingPartners = () => ({
  type: GET_PARTNERS,
});


export const getLandingPartnersSucceed = (payload: any) => ({
  type: GET_PARTNERS_SUCCEED,
  payload,
})

export const getLandingFAQ = () => ({
  type: GET_FAQ,
});


export const getLandingFAQSucceed = (payload: any) => ({
  type: GET_FAQ_SUCCEED,
  payload,
})
// CHECKOUT PAGE
export const getPlanPrice = () => ({
  type: GET_PRICE_PLAN
});


export const getPlanPriceSucceed = (payload: any) => ({
  type: GET_PRICE_PLAN_SUCCEED,
  payload
})

export const generateInvoice = (payload: any) => ({
  type: GENERATE_INVOICE,
  payload
});

export const generateInvoiceSucceed = (payload: any) => ({
  type: GENERATE_INVOICE_SUCCEED,
  payload,
})

export const generateInvoiceFailed = (payload: any) => ({
  type: GENERATE_INVOICE_FAILED,
  payload,
})

export const renewMembership = () => ({
  type: RENEW_MEMBERSHIP,
});

export const renewMembershipSucceed = () => ({
  type: RENEW_MEMBERSHIP_SUCCEED,
})

export const renewMembershipFailed = (payload: any) => ({
  type: RENEW_MEMBERSHIP_FAILED,
  payload,
})

export const getCheckoutStaticContent = () => ({
  type: GET_CHECKOUT_STATIC_CONTENT
});

export const getCheckoutStaticContentSucceed = (payload: any) => ({
  type: GET_CHECKOUT_STATIC_CONTENT_SUCCEED,
  payload,
})

// LOGIN

export const storeUser = (payload: any) => ({
  type: STORE_USER,
  payload,
})

export const storeUserSucceed = (payload: any) => ({
  type: STORE_USER_SUCCEED,
  payload,
})

export const storeUserFailed = (payload: any) => ({
  type: STORE_USER_FAILED,
  payload,
})

export const getMe = () => ({
  type: GET_ME,
});

export interface ProductUse {
  id: number;                 // slot id
  product_id: number | null;  // major benefit id
}

export const getMeSucceed = (hasActiveMembership: boolean, membership_id: number | null, daysLeft: number | null, productUses: ProductUse[]) => ({
  type: GET_ME_SUCCEED,
  hasActiveMembership,
  membership_id,
  daysLeft,
  productUses,
})

export const redirect = (redirect: boolean) => ({
  type: REDIRECT,
  redirect,
})


//membershiphome 

export const getMembershipHomeMajor = () => ({
  type: GET_MEMBERSHIP_HOME_MAJOR,
});


export const getMembershipHomeMajorSucceed = (payload: any) => ({
  type: GET_MEMBERSHIP_HOME_MAJOR_SUCCEED,
  payload,
});

export const getMembershipHomeMajorFailed = (payload: any) => ({
  type: GET_MEMBERSHIP_HOME_MAJOR_FAILED,
  payload,
})

export const getMembershipHomeVaucher = () => ({
  type: GET_MEMBERSHIP_HOME_VAUCHER,
});


export const getMembershipHomeVaucherSucceed = (payload: any) => ({
  type: GET_MEMBERSHIP_HOME_VAUCHER_SUCCEED,
  payload,
});

export const getMembershipHomeVaucherFailed = (payload: any) => ({
  type: GET_MEMBERSHIP_HOME_VAUCHER_FAILED,
  payload,
})

export const getMembershipHomeMG = (payload: any) => ({
  type: GET_MEMBERSHIP_HOME_MG,
  payload
});


export const getMembershipHomeMGSucceed = (payload: any) => ({
  type: GET_MEMBERSHIP_HOME_MG_SUCCEED,
  payload,
});

export const getMembershipHomeMGFailed = (payload: any) => ({
  type: GET_MEMBERSHIP_HOME_MG_FAILED,
  payload,
})
export const getMembershipHomeSG = (payload: any) => ({
  type: GET_MEMBERSHIP_HOME_SG,
  payload,
});


export const getMembershipHomeSGSucceed = (payload: any) => ({
  type: GET_MEMBERSHIP_HOME_SG_SUCCEED,
  payload,
});

export const getMembershipHomeSGFailed = (payload: any) => ({
  type: GET_MEMBERSHIP_HOME_SG_FAILED,
  payload,
})

export const createVoucherCode = (id: number) => ({
  type: CREATE_VOUCHER_CODE,
  id,
});

export const createVoucherCodeSucceed = (id: number) => ({
  type: CREATE_VOUCHER_CODE_SUCCEED,
  id,
});

export const createVoucherCodeFailed = (id: number) => ({
  type: CREATE_VOUCHER_CODE_FAILED,
  id,
});

export const changeBenefit = (payload: {product_use_id: string, product_id: string}) => ({
  type: CHANGE_MAJOR_BENEFIT,
  payload,
});


export const changeBenefitSucceed = (payload: any) => ({
  type: CHANGE_MAJOR_BENEFIT_SUCCEED,
  payload,
});

export const changeBenefitFailed = (payload: any) => ({
  type: CHANGE_MAJOR_BENEFIT_FAILED,
  payload,
})

export const getMembershipHomeWG = () => ({
  type: GET_MEMBERSHIP_HOME_WG,
});


export const getMembershipHomeWGSucceed = (payload: any) => ({
  type: GET_MEMBERSHIP_HOME_WG_SUCCEED,
  payload,
});


export const getMembershipHome = () => ({
  type: GET_MEMBERSHIP_HOME,
})


export const getMembershipHomeSucceed = (payload: any) => ({
  type: GET_MEMBERSHIP_HOME_SUCCEED,
  payload,
})

export const hideSuccessfulPaymentMessage = () => ({
  type: HIDE_SUCCESSFUL_PAYMENT_MESSAGE,
})

export const getMembershipHomeStatic = () => ({
  type: GET_MEMBERSHIP_HOME_STATIC
});


export const getMembershipHomeStaticSucceed = (payload: any) => ({
  type: GET_MEMBERSHIP_HOME_STATIC_SUCCEED,
  payload,
});

export const getMembershipHomeWiningChanceSucceed = (payload) => ({
  type: GET_MEMBERSHIP_HOME_WINNING_CHANCE_SUCCEED,
  payload
});

export const getMembershipHomeReferenceCount = (payload) => ({
  type: GET_MEMBERSHIP_HOME_REFERENCE_COUNT,
  payload
});

// BACKSTAGE - STUDOSTAGE

export const addMembership = (id: number, isBackStage: boolean) => ({
  type: ADD_MEMBERSHIP,
  id,
  isBackStage,
});


export const deleteMemberShip = (id: number, isBackStage: boolean) => ({
  type: DELETE_MEMBERSHIP,
  id,
  isBackStage,
})


// USERACCOUNT


export const getLoginStaticContent = () => ({
  type: GET_LOGIN_STATIC_CONTENT
});

export const getLoginStaticContentSucceed = (payload: any) => ({
  type: GET_LOGIN_STATIC_CONTENT_SUCCEED,
  payload,
})

export const updateIsLoggedIn = (payload: boolean) => ({
  type: UPDATE_IS_LOGGED_IN,
  payload,
})

// GIFT

export const generateGiftInvoice = (payload) => ({
  type: GENERATE_GIFT_INVOICE,
  payload,
})

export const generateGiftInvoiceSucceed = (payload) => ({
  type: GENERATE_GIFT_INVOICE_SUCCEED,
  payload,
})

export const generateGiftInvoiceFailed = (payload) => ({
  type: GENERATE_GIFT_INVOICE_FAILED,
  payload,
})


export const checkCouponCode = (payload) => ({
  type: CHECK_COUPON_CODE,
  payload,
})

export const checkCouponCodeSucceed = (payload) => ({
  type: CHECK_COUPON_CODE_SUCCEED,
  payload,
})

export const checkCouponCodeFailed = (payload) => ({
  type: CHECK_COUPON_CODE_FAILED,
  payload,
})

export const activateMembership = (payload) => ({
  type: ACTIVATE_MEMBERSHIP,
  payload,
})


export const activateMembershipSucceed = () => ({
  type: ACTIVATE_MEMBERSHIP_SUCCEED,
})


export const activateMembershipFailed = () => ({
  type: ACTIVATE_MEMBERSHIP_FAILED,
})

export const redirectToPayment = (url, price) => ({
  type: REDIRECT_TO_PAYMENT,
  url,
  price,
})


export const toggleGiftToastr = () => ({
  type: TOGGLE_GIFT_TOASTR,
})

export const resetCheckingCouponCode = () => ({
  type: RESET_CHECKING_COUPON_CODE,
})

export const getDeliveryPrice = () => ({
  type: GET_DELIVERY_PRICE,
})

export const setDeliveryPrice = (price) => ({
  type: SET_DELIVERY_PRICE,
  price
})
