import { combineReducers } from 'redux'
import userAccount from './pages/UserAccount/reducer';
import majorBenefits from './pages/BenefitPage/reducer';
import landing from './pages/MainPage/reducer';
import checkout from './pages/Checkout/reducer';
import membershipHome from './pages/EventList/reducer';
import giftInvoice from './pages/Gift/reducer';

const rootReducer =  combineReducers({
  userAccount,
  majorBenefits,
  landing,
  checkout,
  membershipHome,
  giftInvoice,
})

export default rootReducer;