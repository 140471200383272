import * as React from 'react';
import MainSection from '../../src/Landing/MainSection';
import { Link, Element } from 'react-scroll';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getLandingBenefits, getLandingStaticContent, getLandingBenefit, storeUser, redirect, getMe, toggleGiftToastr } from '../actionCreators';
import Toastr from '../pages/EventList/Toastr';

class Landing extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      isVideoMuted: true,
      loginClicked: false,
      showToastr: true,
    };
    this.becomeMember = this.becomeMember.bind(this);
  }

  componentDidMount() {
    const { getBenefits, getContent, storeData, cb, history } = this.props;
    window.addEventListener('beforeunload', this.props.toggleGiftToastr);
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    getBenefits();
    getContent();
    if (history.location.state)
      localStorage.setItem("anchor", history.location.state.from)
  }

  componentDidUpdate(prevProps: any) {
    const { userHasActiveMemberShip, history } = this.props;
    if (userHasActiveMemberShip) {
      const anchor = !localStorage.getItem("anchor") || localStorage.getItem("anchor") === "undefined" || localStorage.getItem("anchor") === "null"?  "" : localStorage.getItem("anchor");
      history.push("/membership/" + anchor);
    }
  }


  componentWillUnmount() {
    this.props.redirectPage(false);
    this.props.toggleGiftToastr(false)
    this.setState({ showToastr: false })
    window.removeEventListener('beforeunload', this.props.toggleGiftToastr);
  }


  handleMuteState = () => {
    this.setState((prevState: { isVideoMuted: any; }) => ({
      isVideoMuted: !prevState.isVideoMuted
    }));
  }

  becomeMember(){
    const { userHasActiveMemberShip, history } = this.props;
    history.push(userHasActiveMemberShip ? "/membership" : "/benefits")
  }

  public render() {
    const { content, history, variables, redirectPage, userHasActiveMemberShip, benefits, showGiftToastr } = this.props;
    const { showToastr } = this.state;
    return (
      <div className="landing version0101">
        <div className="landing-video" style={content.image_url ? {backgroundImage: "url(" + content.image_url + ")"} : {}}>
          {
            content.video_url ? (
            <video playsInline loop autoPlay muted={this.state.isVideoMuted} src={content.video_url} className="bg-video">
              <source type="video/mp4" src={content.video_url} />
            </video>
            ) : (
              <div></div>
            )
          }
          <div className="video-container">
            <div className="video-gradient-box">
              <div className="entry-buttons d-flex">
                  <div className="register-btn basic-btn" onClick={() => history.push(`/buy-gift`)}>
                  <div className="gift-btn-row d-flex">
                        <div className="gift-box-btn"/>
                        <div>Membership als geschenk</div>
                      </div>
                    </div>
                <div className="member-btn danger-btn" onClick={this.becomeMember}>
                  {variables && variables.become_member}
                </div>
              </div>
              <div className="member-text">
                {variables && variables.reason_for_one}
              </div>
              <div className="control-row d-flex">
                <Link
                  className="down-arrow bounce"
                  to="main-section"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                </Link>
              </div>
            </div>
          </div>
          {
            content.video_url ? (
                <div className="mute-video">
                  <button onClick={this.handleMuteState} className={this.state.isVideoMuted ? 'mute' : 'unmute'} />
                </div>
            ) : (
                <div></div>
            )
          }
        </div>
        <Element name="main-section" className="topik">
          {
            showToastr && showGiftToastr ? (
            <div className="toastr-wrapper">
              <Toastr
                onClose={() => this.setState({ showToastr: false })}
                title={variables && variables.membership_gift_confirmation_title}
                text={variables && variables.membership_gift_confirmation_text}
                />
              </div>
            ) : null 
          }
          <MainSection
              benefits={benefits}
              content={content}
              variables={variables}
              userHasActiveMemberShip={userHasActiveMemberShip}
              history={history}
              handleClick={this.becomeMember}
          />
        </Element>
      </div>
    );
  }
}


const mapStateToProps = (store: any) => {
  const { landing } = store;
  return {
    benefits: landing.benefits,
    content: landing.content,
    variables: landing.templateVariables,
    userHasActiveMemberShip: store.userAccount.userHasActiveMembership,
    redirect: store.userAccount.redirect,
    cb: store.landing.cb,
    showGiftToastr: store.giftInvoice.showGiftToastr,
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  getContent: () => dispatch(getLandingStaticContent()),
  getBenefits: () => dispatch(getLandingBenefits()),
  getBenefit: (id: number) => dispatch(getLandingBenefit(id)),
  storeData: (data: any) => dispatch(storeUser(data)),
  redirectPage: (redi: boolean) => dispatch(redirect(redi)),
  getUser: () => dispatch(getMe()),
  toggleGiftToastr: () => dispatch(toggleGiftToastr())
})


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Landing));