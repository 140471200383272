import * as React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import Slider from "react-slick";
import Card from './Card';
import moment from 'moment-with-locales-es6';
import Toastr from './Toastr';
import {
  getMembershipHomeMajor,
  getMembershipHomeVaucher,
  getMembershipHomeMG,
  getMembershipHomeSG,
  createVoucherCode,
  changeBenefit,
  getMembershipHomeWG,
  getMembershipHome,
  hideSuccessfulPaymentMessage,
  getMe,
  storeUser,
  getMembershipHomeStatic,
  renewMembership,
  renewMembershipSucceed,
  ProductUse,
} from '../../actionCreators';
import ReactMarkdown from "react-markdown";
import {  MarkdownPreview  } from 'react-marked-markdown';
import { SHOPIFY_ACTION_ID, UPLOADED_DISCOUNT_ACTION_ID } from '../../constants';
import CodeButton from '../../components/CodeButton';
import SubscribeButton from '../../components/SubscribeButton';
import getUserInfo from '../../lib/get-user-info';

class EventList extends React.Component<any, any>  {

    constructor ( props: any  ) {
         super( props) 
         this.state = {
           show: true,
           majorBenefit: {},
           voucherCodes: {},
        };
         this.hideDiv = this.hideDiv.bind(this)
    }

    componentDidMount() {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      window.addEventListener('beforeunload', this.props.hideToastr);
      const { getMembershipData, getUser, getStatic, storeData} = this.props;
      
      getUser();

      getUserInfo()
        .then((userInfo: any) => {
          getMembershipData()
          this.setState({ id: userInfo.sub })
          storeData({
            user: userInfo
          })
        })
      getStatic();
    }

    componentWillUnmount(){
      this.props.hideToastr()
      window.removeEventListener('beforeunload', this.props.hideToastr);
    }
    
    productHeights () {
      var tagDesc = document.getElementsByClassName('introSize');
      var arr = [].slice.call(tagDesc);
      var maxHeight = 0;
      arr.forEach((item: any, i: number) => {
          if (maxHeight < item.clientHeight) {
              maxHeight = item.clientHeight;
          }
      });
      var style = document.createElement('style');
      style.type = 'text/css';
      style.innerHTML = '.productDescHeightDynamic { min-height: ' + maxHeight + 'px; }';
      document.getElementsByTagName('head')[0].appendChild(style);
      for(var i = 0; i < tagDesc.length; i++) {
          tagDesc[i].className += ' productDescHeightDynamic';
      }
      var tagPrice = document.getElementsByClassName('introPrice');
      var arr = [].slice.call(tagPrice);
      var maxHeight = 0;
      arr.forEach((item: any, i: number) => {
          if (maxHeight < item.clientHeight) {
              maxHeight = item.clientHeight;
          }
      });
      var style = document.createElement('style');
      style.type = 'text/css';
      style.innerHTML = '.productPriceHeightDynamic { min-height: ' + maxHeight + 'px; }';
      document.getElementsByTagName('head')[0].appendChild(style);
      for(var i = 0; i < tagPrice.length; i++) {
          tagPrice[i].className += ' productPriceHeightDynamic';
      }
      var tagPrice = document.getElementsByClassName('btn-area');
      var arr = [].slice.call(tagPrice);
      var maxHeight = 0;
      arr.forEach((item: any, i: number) => {
          if (maxHeight < item.clientHeight) {
              maxHeight = item.clientHeight;
          }
      });
      var style = document.createElement('style');
      style.type = 'text/css';
      style.innerHTML = '.productBtnHeightDynamic { min-height: ' + maxHeight + 'px; }';
      document.getElementsByTagName('head')[0].appendChild(style);
      for(var i = 0; i < tagPrice.length; i++) {
          tagPrice[i].className += ' productBtnHeightDynamic';
      }
}
    
    componentDidUpdate(prevProps:any) {
      const { vauchers, userHasActiveMemberShip, history } = this.props;
      if (window.location.hash) {
        const elId = window.location.hash.slice(1)
        const el = document.getElementById(elId);
        if (el) {
          el.scrollIntoView()   
        }
        localStorage.removeItem("anchor");
      }
      const prevVauchers = prevProps.vauchers;
      if (userHasActiveMemberShip === false) {
        history.push("/")

      }
      if (prevVauchers !== vauchers) {
        const voucherCodes:any = {};
        vauchers.forEach((v:any) => {
          if (v.coupon_code)
            voucherCodes[v.coupon_code] = false;
        });
        this.setState({ voucherCodes })
        this.productHeights();
      }
    }

    hideDiv = () => {
        const { show } = this.state;
        this.setState ( { show: !show } )
        this.props.hideToastr()
    }

   renderTitle = () => {
    const { variables, majors } = this.props;
    let majorBeneftChoose = 3;
    for (let i = 0; i<majors.length; i++) {
      if (majors[i].membership_id!==null && i===0) {
        if (majors[i].approval_date !== null) {
          majorBeneftChoose = 2;
          break;
        }
        else{
          majorBeneftChoose = 1;
          break;
        }
      }
    }
    return majorBeneftChoose === 1  ? (
      <React.Fragment>
        <img src={require('../../img/attention.svg')} width="24" height="24" alt="" />
        {variables.major_benefits_change_possible}
      </React.Fragment>
      ) : (majorBeneftChoose === 2 ? "" : (
        <React.Fragment>
          <img src={require('../../img/attention.svg')} width="24" height="24" alt="" />
           {variables.major_benefits_choose_now}
        </React.Fragment>
      ));
   }

  renderSliderItems = () => {
    const { productUses, majors, variables } = this.props;
    const availableProductUsesCount = Array.isArray(productUses)
      ? productUses.filter(productUse => productUse.product_id === null).length
      : 0;

    if (!productUses) {
      return <div className={'popUpModal'}>
        <div className={'memberModal load-wrapper'}>
          <img
            src={require('../../img/loading.gif')}
            className={'loading bounce-load'}
            alt={'Loading major benefits...'}
          />
        </div>
      </div>;
    }

    if (Array.isArray(productUses) && productUses.length === 0) {
      return <div>
        No major benefits available.
      </div>;
    }

    return majors.map((membership: any) => {
      const removeBenefitButton = <div className={'removeBenefitButton'}>
        <a onClick={() => this.onRemoveMajorBenefit({id: membership.id})}>
          Auswahl entfernen
        </a>
      </div>;

      const productUsesForCurrentMajor = productUses.filter(productUse => productUse.product_id === membership.id);
      const areProductUsesApproved = productUsesForCurrentMajor.some(({approved}) => approved);
      const counter = productUsesForCurrentMajor.length;
      const isSelected = counter > 0;
      const isApproved = moment().isAfter(membership.approval_date) || areProductUsesApproved;
      const isComingSoon = moment().isBefore(membership.available_from);

      return (
        <div className="firstSliderItem clear-box-container" key={membership.id}>
          <div className="sliderItem">
            <div className="sliderItemImg">
              <img src={membership.images && membership.images[0]} alt=""/>
            </div>
            <div className="sliderItemTitle">
              {membership.title}
            </div>
            <div className="sliderItemText" dangerouslySetInnerHTML={{__html: membership.description}}/>
            <div className="sliderItemData">
              {membership.product_start_date && moment(membership.product_start_date).locale('de').format("D. MMMM YYYY")}
            </div>
            <div className="sliderItemData">
              {membership.location}
            </div>
            <div className="sliderItemLinkBox">
              {
                isComingSoon && moment().isAfter(membership.display_end)
                  ? isComingSoon
                    ? <div className="hiddenEventButton">
                      {variables && variables.coming_soon}
                    </div>
                    : <div className="hiddenEventButton">
                      {variables && variables.not_available}
                    </div>
                  : <div className={`
                    sliderItemLink
                    ${isSelected ? 'sliderItemLinkSelected' : ''}
                    ${(!isSelected && availableProductUsesCount === 0) ? 'hiddenEventButton' : ''}
                  `}
                   onClick={
                     () => (isApproved && isSelected) || isSelected || availableProductUsesCount === 0
                       ? null
                       : this.onChooseMajorBenefit({id: membership.id})
                   }
                  >
                    {counter ? <span>{counter} x </span> : null}
                    {variables && (counter > 0 ? variables.chosen : variables.choose)}
                  </div>
              }
              {
                !isApproved && isSelected
                  ? removeBenefitButton
                  : null
              }
              {
                isSelected && availableProductUsesCount > 0 && moment().isBefore(membership.display_end)
                  ? <div className={`sliderItemLink`}
                         onClick={() => this.onChooseMajorBenefit({id: membership.id})}
                  >
                    {'NOCHMALS WÄHLEN'}
                  </div>
                  : null
              }
            </div>
          </div>
          {
            isSelected
              ? <div className="rightLabel clear-box">
                <div className="rl-check-container">
                  <div className="rl-check-logo"/>
                </div>
                {variables && variables.major_benefits_chosen_description_change_possible.replace("{chosen_major_benefit}", membership.name)}
                {variables && variables.major_benefits_chosen_description_change_possible_more}
              </div> : null
          }
        </div>
      );
    });
  }

  onChooseMajorBenefit = ({id}: {id: number}): void => {
    const { productUses } = this.props;
    const freeProductUse = productUses.find((productUse: ProductUse) => productUse.product_id === null);

    if (freeProductUse) {
      this.props.changeMajorBenefit({
        product_use_id: null,
        new_product_id: id,
      }); // NOTE (damian): doesn't update store; there is an extra call made after it fulfills; not optimal
    }
  }

  onRemoveMajorBenefit = ({id}: {id: number}): void => {
    const { productUses } = this.props;
    const productUseToRemove = productUses.filter((productUse: ProductUse) => productUse.product_id === id)[0];

    if (productUseToRemove) {
      this.props.changeMajorBenefit({
        product_use_id: productUseToRemove.id,
        new_product_id: null,
      }); // NOTE (damian): doesn't update store; there is an extra call made after it fulfills; not optimal
    }
  }

    renewButtonClicked = () => {
      this.props.renewMembership();
    }

    public render() {
      const {
        variables, history, meetGreets, studioGuides, vauchers, majors, welcomeGifts, invoice, content, membershipId,winningChance, activateMembership,
        daysLeft, renewMembershipState,
        } = this.props;
        var selectedBenefitValue = false;
        this.props.majors.map((membership: any, i: number) => {
            if (membership.membership_id !== null) {
                selectedBenefitValue = true;
            }
        })

        var settings = {
          dots: false,
          infinite: false,
          initialSlide: 0,
          speed: 300,
          slidesToShow: 3,
          slidesToScroll: 1,
          adaptiveHeight: true,
          variableWidth: true,
          arrows: majors.length > 3 && selectedBenefitValue ? true : false,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: false,
                dots: true
              }
            },
            {
              breakpoint: 768,
              settings: {
                dots: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: false,    
              }
            },
            {
              breakpoint: 600,
              settings: {
                dots: true,
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: false,
    
              }
            },
            {
              breakpoint: 480,
              settings: {
                infinite: false,
                dots: true,
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        };

        const { show } = this.state;
        const isRenewMembershipLoading = (renewMembershipState && renewMembershipState.isLoading);

        return (
            <>
            {daysLeft < 46 && daysLeft > 0 && 
            <div className="wrapper">
              <div className="membership-info-wrapper">
                <div className="membership-info-title">
                  {variables.membership_expiry_info_title}
                </div>
                <div className="membership-info-text">
                  {variables.membership_expiry_info_text}
                </div>
                <div className="membership-info-subtitle">
                  {`${variables.membership_expiry_info_subtitle_text} ${daysLeft} ${variables.membership_expiry_info_subtitle_unit}`}
                </div>
                <button
                  className="membership-info-button"
                  onClick={this.renewButtonClicked}
                  disabled={isRenewMembershipLoading}
                >
                  {
                    isRenewMembershipLoading
                      ? 'Loading...'
                      : variables.membership_expiry_info_button_text
                  }
                </button>
                {
                  Boolean(this.props.renewMembershipState.error)
                    ? <Toastr
                      isError
                      onClose={this.props.clearErrorInRenewMembershipState}
                      title={'Error'}
                      text={'Please, try again later...'}
                    />
                    : null
                }
              </div>
            </div>}
            <div className="eventListView memebershipStart main-frame one-bg">
                <div className="wrapper">
                  { (show && invoice) || (show && activateMembership) ? (
                    <Toastr
                      onClose={this.hideDiv}
                      title={variables.membership_confirmation_title}
                      text={variables.membership_confirmation_text}
                    />
                  ) : null }
                  <div className="welcome-box d-flex">
                    <div className="content-box col_60">
                      <div className="event-title">
                        {content && content.body && content.body[0].title}
                      </div>
                      <div className="event-description">
                          {content && content.body && <MarkdownPreview value={ content.body[0].body.replace("{membership_id}", membershipId) }/>}
                      </div>
                    </div>
                    <div className="col_40">
                      <div className="event-thumbnail" style={content && content.body && content.body[0].image ? {backgroundImage: "url(http:" + content.body[0].image  + ")"} : {}}>
                      <div className="one-overlay d-flex align-items">
                          <div className="overlay-content">
                           <img src={require('../../img/white-logo.svg')} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="subtitle">
                    <div className="subtitle-box">
                      <div className="subtitle-title">
                        {content && content.body && content.body[1].title}
                      </div>
                      <div className="subtitle-text">
                        {content && content.body && <ReactMarkdown source={ content.body[1].body }/>}
                      </div>
                    </div>
                  </div>
                    <div className="ticket">
                        <div className="attention d-flex">
                          <div className="smallText">
                            {this.renderTitle()}
                          </div>
                        </div>
                    </div>
                    <div className="eventListSlider">
                        <Slider {...settings}>
                            {this.renderSliderItems()}
                        </Slider>
                    </div>
                    <div className="divider d-flex">
                      <div className="divider-text">{content && content.body && content.body[2].title}</div>
                      <div className="divider-one-log">
                        <img src={require('../../img/one_logo_white.svg')} width="100"  alt="" />
                      </div>
                    </div>
                    {
                        welcomeGifts && welcomeGifts.length ? (
                    <div className="about-product" id={"ancMembership" + welcomeGifts[0].id}>
                        {/*<div className="smallText">*/}
                        {/*    { variables &&  variables.welcome_gift_subtitle}*/}
                        {/*</div>*/}
                        <div className="eventProductBox d-flex">
                            <div className="singleProductItem">
                                  <div className="product-img">
                                    <img src={welcomeGifts && welcomeGifts[0].images && welcomeGifts[0].images[0]} alt="" />
                                  </div>    
                                      {/*                              
                                    <figcaption>
                                        <div className="singleProductName">
                                          { welcomeGifts.length && welcomeGifts[0].name}
                                        </div>
                                    <div className="singleProductDescription">
                                            100% Cotton Canvas
                                        </div>
                                        <div className="singleProductSiz">
                                            S M L XL
                                        </div> 
                                    </figcaption>
                                    */}
                            </div>
                            <div className="gift-product">
                              <div className="gift-product-title">
                                { welcomeGifts && welcomeGifts[0].name}
                              </div>
                            <div className="aboutSingleProduct" dangerouslySetInnerHTML={{ __html: (welcomeGifts && welcomeGifts[0].description)}} />
                            </div>
                        </div>
                    </div>
                        ): null
                    }
                    {
                      winningChance && winningChance.length ? (
                        winningChance.map((winningChanceEntry) => {
                          return (
                            <div className="winning-box" id={"ancMembership" + winningChanceEntry.id} style={{backgroundImage: "linear-gradient(90deg, rgba(0,0,0,0.6), transparent), url(" + (winningChanceEntry.images ? winningChanceEntry.images[0] : "")  + ")"}}>
                                <div className="w-box-item">
                                  <div className="w-box-title">
                                    {winningChanceEntry.title}
                                  </div>
                                  <div className="w-box-description" dangerouslySetInnerHTML={{ __html: winningChanceEntry.description}} />
                                  {
                                    winningChanceEntry.product_action_id === SHOPIFY_ACTION_ID ||  winningChanceEntry.product_action_id === UPLOADED_DISCOUNT_ACTION_ID? (
                                      <CodeButton product={ winningChanceEntry} />
                                    ) : <SubscribeButton product={ winningChanceEntry}/>
                                  }
                                </div>
                            </div>               
                          )
                        })
                      ): null
                    }

                    <div className="divider d-flex">
                      <div className="divider-text">{content && content.body && content.body[3].title}</div>
                      <div className="divider-one-log">
                        <img src={require('../../img/one_logo_white.svg')} width="100"  alt="" />
                      </div>
                    </div>
                    <div className="shop-item-description">
                    {content && content.body && content.body[3].body}
                    </div>
                    <div className="eventItemLoop">
                      <div className="loop-row d-flex">
                      {/*<div className="eventSelectProduct">*/}
                      {/*        <div className="introImg">*/}
                      {/*        <img src={require('../../img/36.png')} alt="" />*/}
                      {/*        </div>*/}
                      {/*        <div className="introBox">*/}
                      {/*            <div className="introTitle">Energy Air 10% auf Ticket Upgrades</div>*/}
                      {/*            <div className="introSize">*/}
                      {/*              100% Cotton Canvas <br />*/}
                      {/*              One Size*/}
                      {/*            </div>*/}
                      {/*            <div className="introPrice">*/}
                      {/*                <div className="normalPrice">*/}
                      {/*                    <span className="through">CHF 20.90</span>*/}
                      {/*                </div>*/}
                      {/*                <div className="memeberPrice">*/}
                      {/*                  One Membership Preis CHF 15.90*/}
                      {/*                </div>*/}
                      {/*            </div>*/}
                      {/*                <div className="btn-area">*/}
                      {/*                <div className="memberItemButtons d-flex">*/}
                      {/*                    <div className="rabattBtnRed">ONE-EAIR19</div>*/}
                      {/*                    <div className="itemCopyRedButton"></div>*/}
                      {/*                </div>*/}
                      {/*                <div className="rabattBtn" onClick={() => window.open("https://shop.energy.ch/")}>Zum Product</div>*/}
                      {/*                </div>*/}
                      {/*        </div>*/}
                      {/*    </div>*/}
                        {
                          vauchers.map((vaucher:any, i:number) => (
                            <Card
                              key={i}
                              product={vaucher}
                              variables={variables}
                              index={i}
                            />
                          ))
                        }
                      </div>
                    </div>

                    <div className="winning-box" style={{backgroundImage: "linear-gradient(90deg, rgba(0,0,0,0.6), transparent), url(http:" + (content && content.body ? content.body[4].image : "")  + ")"}}>
                          <div className="w-box-item">
                            <div className="w-box-title">
                            {content && content.body  && content.body[4].title}
                            </div>
                            <div className="w-box-description">
                            {content && content.body && content.body[4].body}
                            </div>
                            <div className="w-box-btn basic-btn" onClick={()=> history.push("/meet-and-greet-and-studio-guides")}>
                                WEITERE INFOS
                            </div>
                          </div>
                      </div>  
                </div> 
            </div>
          </>
        );
    }
}

const mapStateToProps = (store:any) => ({
  variables: store.landing.templateVariables,
  majors: store.membershipHome.majors,
  vauchers: store.membershipHome.vauchers,
  meetGreets: store.membershipHome.meetGreets,
  studioGuides: store.membershipHome.studioGuides,
  welcomeGifts: store.membershipHome.welcomeGifts,
  invoice: store.checkout.invoice,
  userHasActiveMemberShip: store.userAccount.userHasActiveMembership,
  membershipId: store.userAccount.membershipId,
  content: store.membershipHome.content,
  winningChance: store.membershipHome.winningChance,
  activateMembership: store.checkout.activateMembership,
  daysLeft: store.userAccount.daysLeft,
  renewMembershipState: store.membershipHome.renewMembershipState,
  productUses: store.userAccount.productUses,
});

const mapDispatchToProps = (dispatch: any) => ({
  getMajors: () => dispatch(getMembershipHomeMajor()),
  getVauchers: () => dispatch(getMembershipHomeVaucher()),
  getMeetGreets: (payload: {type: number}) => dispatch(getMembershipHomeMG(payload)),
  getWelcomeGifts: () => dispatch(getMembershipHomeWG()),
  getStudioGuides: (payload: {type: number}) => dispatch(getMembershipHomeSG(payload)),
  handleGenerateCode: (id:number) => dispatch(createVoucherCode(id)),
  changeMajorBenefit: (payload) => dispatch(changeBenefit(payload)),
  getMembershipData: () => dispatch(getMembershipHome()),
  hideToastr: () => dispatch(hideSuccessfulPaymentMessage()),
  getStatic: () => dispatch(getMembershipHomeStatic()),
  storeData: (data: any) => dispatch(storeUser(data)),
  getUser: () => dispatch(getMe()),
  renewMembership: () => dispatch(renewMembership()),
  clearErrorInRenewMembershipState: () => dispatch(renewMembershipSucceed()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EventList));
