import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './store';
import '../src/scss/main.scss'
import * as serviceWorker from './serviceWorker';

// if (process.env.NODE_ENV !== 'development') {
//   console.log = () => ({});
//   console.warn = () => ({});
//   console.error = () => ({});
// }

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')

);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
