import {
  GET_PRICE_PLAN_SUCCEED, GENERATE_INVOICE_SUCCEED, GENERATE_INVOICE_FAILED,
  SET_SELECTED_PRODUCT,
  GET_CHECKOUT_STATIC_CONTENT_SUCCEED,
  HIDE_SUCCESSFUL_PAYMENT_MESSAGE,
  CHECK_COUPON_CODE_SUCCEED,
  CHECK_COUPON_CODE_FAILED,
  ACTIVATE_MEMBERSHIP_SUCCEED,
  ACTIVATE_MEMBERSHIP_FAILED,
  REDIRECT_TO_PAYMENT,
  CHECK_COUPON_CODE,
  RESET_CHECKING_COUPON_CODE
} from '../../actions'


const checkout = (state = {
  planPrice: {},
  benefit : {},
  product: {},
  invoice: false,
  activateMembership: false,
  paymentUrl: null,
  checkCouponCode: false,
  newPrice: null,
 // couponCode: null,
}, action:any) => {
  switch (action.type) {
    case GET_PRICE_PLAN_SUCCEED:
      return {
        ...state,
        planPrice: action.payload,
      }
    case GENERATE_INVOICE_SUCCEED:
      return {
        ...state,
        invoice: action.payload,
      }
    case HIDE_SUCCESSFUL_PAYMENT_MESSAGE:
        return {
          ...state,
          invoice: false,
          activateMembership: false,
        }
    case SET_SELECTED_PRODUCT:
        return {
          ...state,
          product: action.product,
        }
    case GENERATE_INVOICE_FAILED:
        return {
          ...state,
          invocieError: action.payload,
        }
    case GET_CHECKOUT_STATIC_CONTENT_SUCCEED:
        return {
          ...state,
          content: action.payload,
        }
    case CHECK_COUPON_CODE:
        return {
          ...state,
          checkCouponCode: true
        }
    case CHECK_COUPON_CODE_SUCCEED:
      return {
        ...state,
        couponCode: true,
        checkCouponCode: false,
      }
    case CHECK_COUPON_CODE_FAILED:
        return {
          ...state,
          couponCode: false,
          checkCouponCode: false,
        }
    case ACTIVATE_MEMBERSHIP_SUCCEED:
      return {
        ...state,
        activateMembership: true,
      }
    case ACTIVATE_MEMBERSHIP_FAILED:
        return {
          ...state,
          activateMembership: false,
        }
    case REDIRECT_TO_PAYMENT:
      return {
        ...state,
        paymentUrl: action.url,
        newPrice: action.price,
        checkCouponCode: false,
        couponCode: true,
      }
    case RESET_CHECKING_COUPON_CODE:
        return {
          ...state,
          couponCode: null,
          newPrice: null,
          paymentUrl: null,
        }
    default:
      return state
  }
}

export default checkout;