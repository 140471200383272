import * as React from 'react';
import { withRouter } from 'react-router-dom';
import {RouteComponentProps} from "react-router";
import Callback from "../../components/Callback";

export interface  LoginProps extends RouteComponentProps {
  message?: string;
}

export interface  LoginState {
}

const Failed = ({ message }: any) => <div>Login failed: {message} Please try again</div>

class Login extends React.Component< LoginProps,  LoginState> {
  public render() {
    const { history } = this.props;
    return <Callback failed={Failed} router={history}><p>success</p></Callback>
  }
}


export default withRouter(Login);