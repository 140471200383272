import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import MembershipCard from './MembershipCard';
import { getPlanPrice, generateInvoice, getMe, getCheckoutStaticContent, checkCouponCode, activateMembership, resetCheckingCouponCode } from '../../actionCreators';
import LoadingPage from '../../LoadingPage/LoadingPage';
import { getCookie } from '../../utils';
import moment from 'moment-with-locales-es6';
import getUserInfo from '../../lib/get-user-info';

class Checkout extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      product: {
        id: null
      },
      loading: false,
      typing: false,
      typingTimeout: 0,
      coupon_code: null,
      checkCouponCode: false,
    }
    this.handleInputChange = this.handleInputChange.bind(this);
  }



  public componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    const { product, history, getUser, getContent } = this.props;

    getUserInfo()
      .then(() => {
        getUser();
        this.props.getPrice()
        getContent();
        if (product)
        this.setState({ product })
      })
      .catch(() => {
        history.push("/")
      })
  }

  public componentDidUpdate(prevProps: any, prevState: any) {
    const { invoice } = this.props;
    const prevInvoice = prevProps.invoice;
    const { userHasActiveMemberShip, history } = this.props;
    if (invoice !== prevInvoice )
      window.location.href = invoice;
    if (userHasActiveMemberShip) { 
        history.push("/membership")
    }
  }
  
  generateInvoice() {
    this.setState({ loading: true})
    const { planPrice, createInvoice, couponCode, activateMembership, paymentUrl } = this.props;
    const { product, coupon_code } = this.state;

    getUserInfo()
      .then((data) => {
        const { email, sub } = data;
        let body = {};
        if(couponCode && !paymentUrl) {
          body = {
            product_id:  product.id,
            coupon_code
          }
          activateMembership(body)
        }
    else if (paymentUrl) {
       window.location.href = paymentUrl;
     }
        else {
          body = {
           user: {
             email,
             id: sub,
            //  TODO: investigate where this data comes from
            //  first_name: data.first_name,
            //  last_name: data.last_name,
            //  gender: data.male
           },
           plan_price_id: planPrice.id,
           product_id:  product.id,
           failure_url: `${window.location.origin}/${process.env.REACT_APP_BASENAME}/benefits`,
           success_url: `${window.location.origin}/${process.env.REACT_APP_BASENAME}/membership`,
           referer_number: null,
         };
         createInvoice(body);
        }
      })
  }

  handleInputChange(event) {
    const self = this;
    const { checkCode, planPrice, resetCheckingCouponCode, userId } =
      this.props;
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }
    resetCheckingCouponCode();
    self.setState({
      coupon_code: event.target.value,
      typing: false,
      typingTimeout: setTimeout(
        function (val) {
          if (val.length > 7) {
            getUserInfo().then((data) => {
              const { email, sub } = data;

              checkCode({
                id: userId,
                coupon_code: val,
                plan_price_id: planPrice.id,
                success_url: `${window.location.origin}/${process.env.REACT_APP_BASENAME}/checkout`,
                failure_url: `${window.location.origin}/${process.env.REACT_APP_BASENAME}/checkout`,
                user: {
                  email,
                  id: sub,
                },
              });
            });
          } else resetCheckingCouponCode();
        },
        1000,
        event.target.value
      ),
    });
  }

    public render() {
      const { product, loading, coupon_code } = this.state;
      const { planPrice, history, variables, content, couponCode, isCheckCouponCode, paymentUrl, newPrice } = this.props;

        var d = new Date();
        var planDate = new Date(d.getFullYear() + 1, d.getMonth(), d.getDate() - 1);

        return (
            <div className="checkout main-frame one-bg">
              {
                loading && <LoadingPage title={variables && variables.payment_loading_title} subtitle={variables && variables.payment_loading_subtitle} />
              }
                <div className="wrapper">
                    <div className="checkoutArea">
                        <div className="checkoutHeader">
                              <div className="checkoutHeaderTitle">
                                {content.title}
                              </div>
                            <div className="checkoutHeaderText" dangerouslySetInnerHTML={{ __html: content.body && content.body[0].body.content}} />
                        </div>
                        <div className="checkoutItem">
                            <div className="checkoutItemRow d-flex">
                                <div className="productLogo">
                                    <img src={require('../../img/33.png')} width="100" alt="" />
                                </div>
                                <div className="checkoutTwo d-flex">
                                    <div className="aboutProduct">
                                        <div className="productName">
                                           {planPrice && planPrice.name}
                                        </div>
                                        <div className="checkoutProductText">
                                          <div className="include-text">
                                            Bis {moment(planDate).locale('de').format("DD. MMMM YYYY")}
                                          </div>
                                          <div className="include-text">
                                           {planPrice && planPrice.description}
                                          </div>
                                        </div>
                                    </div>
                                    <div className="priceToNow d-flex">
                                        <div className="productPrice">
                                            CHF  { planPrice && planPrice.price }
                                    </div>
                                        <div className="productFavorite">
                                            <button className="addFavorite">
                                                <span>{variables && variables.chosen}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        { product.id && <MembershipCard product={product} chosen={variables && variables.chosen} included={variables && variables.included_for_free}/> }
                        <div className="totalPrice d-flex">
                            <div className="totalNumber d-flex">
                                <div className="endTotal">
                                {variables && variables.total}
                            </div>
                                <div className="endPrice">
                                { newPrice ? newPrice : (planPrice && planPrice.price) }
                            </div>
                            </div>
                        </div>
                        <div className="eventStarterLink d-flex">
                            <div className="eventBackLink default-btn" onClick={ () => history.push("/benefits")}>
                                {variables && variables.back}
                            </div>
                            <div className="gf-coupon-code d-flex">
                                <div className="gf-inputs-row d-flex">
                                  <label className="gf-label">{variables && variables.voucher_code}</label>
                                  <input type="text"
                                    className={`sp-input ${(couponCode!== null && !couponCode) ? "wrong-gf" : ""}`}
                                    onChange={this.handleInputChange}
                                    value={coupon_code}
                                  />
                                  {couponCode!== null && !couponCode ? <span className="wrong-gf-text">Code ungültig</span> : null}
                                  {couponCode ? <span className="success-gf" /> : null}
                                </div>
                                <div className={`eventPrevLink ${ isCheckCouponCode ? "hiddenEventButton" :  "primary-btn"}`} onClick={(e: any) => isCheckCouponCode ? null : this.generateInvoice()}>
                                { isCheckCouponCode ? <img className="loading-spinner" src={require("./loading.svg")} /> : (
                                    couponCode && !paymentUrl? (
                                      variables && variables.activate_membership
                                    ):
                                    variables && variables.pay_now
                                  ) }
                                </div>
                          </div>
                        </div>
                        <div className="onMobile">
                        <div className="gf-coupon-code d-flex">
                          <div className="gf-inputs-row d-flex">
                            <label className="gf-label">{variables && variables.voucher_code}</label>
                            <input type="text"
                                className={`sp-input ${couponCode!== null && !couponCode ? "wrong-gf" : ""}`}
                                onChange={this.handleInputChange}
                                value={coupon_code}
                              />
                              {couponCode!== null && !couponCode ? <span className="wrong-gf-text">Code ungültig</span> : null}
                              {couponCode ? <span className="success-gf" /> : null}
                          </div>
                        </div>
                            <div className={`eventPrevLink ${ isCheckCouponCode ? "hiddenEventButton" :  "primary-btn"}`} onClick={(e:any) =>  isCheckCouponCode ? null : this.generateInvoice()}>
                            { isCheckCouponCode ? <img className="loading-spinner" src={require("./loading.svg")} /> : (
                                couponCode && !paymentUrl ? (
                                  variables && variables.activate_membership
                                ):
                                variables && variables.pay_now
                              ) }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (store: any) => ({
  planPrice: store.checkout.planPrice,
  invoice: store.checkout.invoice,
  product: store.checkout.product,
  variables: store.landing.templateVariables,
  userHasActiveMemberShip: store.userAccount.userHasActiveMembership,
  content: store.checkout.content,
  couponCode: store.checkout.couponCode,
  paymentUrl: store.checkout.paymentUrl,
  isCheckCouponCode: store.checkout.checkCouponCode,
  newPrice: store.checkout.newPrice,
  isLoggedIn: store.userAccount.isLoggedIn,
  userId: store.userAccount.sub,
})

const mapDispatchToProps = (dispatch: any) => ({
  getPrice: () => dispatch(getPlanPrice()),
  createInvoice : (data:any) => dispatch(generateInvoice(data)),
  getUser: () => dispatch(getMe()),
  getContent: () => dispatch(getCheckoutStaticContent()),
  checkCode: payload => dispatch(checkCouponCode(payload)),
  activateMembership: payload => dispatch(activateMembership(payload)),
  resetCheckingCouponCode: () => dispatch(resetCheckingCouponCode()),
});


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Checkout));