export const INITIAL_ACTION = "INITIAL_ACTION";

export const INITIAL_ACTION_SUCCEED = "INITIAL_ACTION_SUCCEED";

export const GET_TEMPLATE_VARIABLES = "GET_TEMPLATE_VARIABLES";

export const GET_TEMPLATE_VARIABLES_SUCCEED = "GET_TEMPLATE_VARIABLES_SUCCEED";

// CHOOSE MAJOR BENEFIT PAGE
export const GET_MAJOR_BENEFITS_STATIC_CONTENT = 'GET_MAJOR_BENEFITS_STATIC_CONTENT';

export const GET_MAJOR_BENEFITS_STATIC_CONTENT_SUCCEED = "MAJOR_BENEFITS_STATIC_CONTENT_SUCCEED";

export const GET_BENEFIT_PRODUCTS = "GET_BENEFIT_PRODUCTS";

export const GET_BENEFIT_PRODUCTS_SUCCEED = "GET_BENEFIT_PRODUCTS_SUCCEED";

export const SET_SELECTED_PRODUCT = "SET_SELECTED_PRODUCT";

// LANDING PAGE
export const GET_LANDING_BENEFITS = "GET_LANDING_BENEFITS";

export const GET_LANDING_BENEFIT = "GET_LANDING_BENEFIT";

export const GET_LANDING_BENEFIT_SUCCEED = "GET_LANDING_BENEFIT_SUCCEED";

export const GET_LANDING_BENEFITS_SUCCEED = "GET_LANDING_BENEFITS_SUCCEED";

export const GET_LANDING_STATIC_CONTENT = "GET_LANDING_STATIC_CONTENT";

export const GET_LANDING_STATIC_CONTENT_SUCCEED = "GET_LANDING_STATIC_CONTENT_SUCCEED";

export const GET_PARTNERS = "GET_PARTNERS";

export const GET_PARTNERS_SUCCEED = "GET_PARTNERS_SUCCEED";

export const GET_FAQ = "GET_FAQ";

export const GET_FAQ_SUCCEED = "GET_FAQ_SUCCEED";

// CHECKOUT
export const GET_PRICE_PLAN = "GET_PRICE_PLAN";

export const GET_PRICE_PLAN_SUCCEED = "GET_PRICE_PLAN_SUCCEED";

export const GENERATE_INVOICE = "GENERATE_INVOICE";

export const GENERATE_INVOICE_SUCCEED = "GENERATE_INVOICE_SUCCEED";

export const GENERATE_INVOICE_FAILED = "GENERATE_INVOICE_FAILED";

export const RENEW_MEMBERSHIP = 'RENEW_MEMBERSHIP';

export const RENEW_MEMBERSHIP_SUCCEED = 'RENEW_MEMBERSHIP_SUCCEED';

export const RENEW_MEMBERSHIP_FAILED = 'RENEW_MEMBERSHIP_FAILED';

export const GET_CHECKOUT_STATIC_CONTENT = "GET_CHECKOUT_STATIC_CONTENT";

export const GET_CHECKOUT_STATIC_CONTENT_SUCCEED = "GET_CHECKOUT_STATIC_CONTENT_SUCCEED";

// LOGIN
 export const STORE_USER = "STORE_USER";

 export const STORE_USER_SUCCEED = "STORE_USER_SUCCEED";

 export const  STORE_USER_FAILED = "STORE_USER_FAILED";

 export const GET_ME = "GET_ME";

 export const GET_ME_SUCCEED = "GET_ME_SUCCEED";

export const GET_ME_FAILED = 'GET_ME_FAILED';

 export const REDIRECT = "REDIRECT";

 export const UPDATE_IS_LOGGED_IN = "UPDATE_IS_LOGGED_IN";

 //MEMERSHIPHOME

 export const GET_MEMBERSHIP_HOME_MAJOR = "GET_MEMBERSHIP_HOME_MAJOR";

 export const  GET_MEMBERSHIP_HOME_MAJOR_SUCCEED = "GET_MEMBERSHIP_HOME_MAJOR_SUCCEED";

 export const GET_MEMBERSHIP_HOME_MAJOR_FAILED = "GET_MEMBERSHIP_HOME_MAJOR_FAILED";

 export const GET_MEMBERSHIP_HOME_VAUCHER = "GET_MEMBERSHIP_HOME_VAUCHER";

 export const  GET_MEMBERSHIP_HOME_VAUCHER_SUCCEED = "GET_MEMBERSHIP_HOME_VAUCHER_SUCCEED";

 export const GET_MEMBERSHIP_HOME_VAUCHER_FAILED = "GET_MEMBERSHIP_HOME_VAUCHER_FAILED";

 export const GET_MEMBERSHIP_HOME_MG = "GET_MEMBERSHIP_HOME_MG";

 export const  GET_MEMBERSHIP_HOME_MG_SUCCEED = "GET_MEMBERSHIP_HOME_MG_SUCCEED";

 export const GET_MEMBERSHIP_HOME_MG_FAILED = "GET_MEMBERSHIP_HOME_MG_FAILED";

 export const GET_MEMBERSHIP_HOME_SG = "GET_MEMBERSHIP_HOME_SG";

 export const  GET_MEMBERSHIP_HOME_SG_SUCCEED = "GET_MEMBERSHIP_HOME_SG_SUCCEED";

 export const GET_MEMBERSHIP_HOME_SG_FAILED = "GET_MEMBERSHIP_HOME_SG_FAILED";

 export const CREATE_VOUCHER_CODE = "CREATE_VOUCHER_CODE";

 export const CREATE_VOUCHER_CODE_SUCCEED = "CREATE_VOUCHER_CODE_SUCCEED";

 export const CREATE_VOUCHER_CODE_FAILED = "CREATE_VOUCHER_CODE_FAILED";

 export const CHANGE_MAJOR_BENEFIT = "CHANGE_MAJOR_BENEFIT";

 export const CHANGE_MAJOR_BENEFIT_SUCCEED = "CHANGE_MAJOR_BENEFIT_SUCCEED";

 export const CHANGE_MAJOR_BENEFIT_FAILED = "CHANGE_MAJOR_BENEFIT_FAILED";

 export const ADD_MEMBERSHIP = "ADD_MEMBERSHIP";

 export const ADD_MEMBERSHIP_SUCCEED = "ADD_MEMBERSHIP_SUCCEED";

 export const DELETE_MEMBERSHIP = "ADD_MEMBERSHIP_SUCCEED";

 export const GET_MEMBERSHIP_HOME_WG = "GET_MEMBERSHIP_HOME_WG";

 export const  GET_MEMBERSHIP_HOME_WG_SUCCEED = "GET_MEMBERSHIP_HOME_WG_SUCCEED";

 export const GET_MEMBERSHIP_HOME_WG_FAILED = "GET_MEMBERSHIP_HOME_WG_FAILED";

 export const GET_MEMBERSHIP_HOME = "GET_MEMBERSHIP_HOME";

 export const GET_MEMBERSHIP_HOME_SUCCEED = "GET_MEMBERSHIP_HOME_SUCCEED";

 export const HIDE_SUCCESSFUL_PAYMENT_MESSAGE = "HIDE_SUCCESSFUL_PAYMENT_MESSAGE";

 export const GET_MEMBERSHIP_HOME_STATIC = "GET_MEMBERSHIP_HOME_STATIC";

 export const GET_MEMBERSHIP_HOME_STATIC_SUCCEED = "GET_MEMBERSHIP_HOME_STATIC_SUCCEED";

 export const GET_MEMBERSHIP_HOME_WINNING_CHANCE_SUCCEED = "GET_MEMBERSHIP_HOME_WINNING_CHANCE_SUCCEED";

 export const GET_MEMBERSHIP_HOME_REFERENCE_COUNT = "GET_MEMBERSHIP_HOME_REFERENCE_COUNT";

 // USER ACCOUNT

 export const GET_LOGIN_STATIC_CONTENT = "GET_LOGIN_STATIC_CONTENT";

 export const GET_LOGIN_STATIC_CONTENT_SUCCEED = "GET_LOGIN_STATIC_CONTENT_SUCCEED";

 // GIFT

 export const GENERATE_GIFT_INVOICE = "GENERATE_GIFT_INVOICE";

 export const GENERATE_GIFT_INVOICE_SUCCEED = "GENERATE_GIFT_INVOICE_SUCCEED";

 export const GENERATE_GIFT_INVOICE_FAILED = "GENERATE_GIFT_INVOICE_FAILED";

 export const CHECK_COUPON_CODE = "CHECK_COUPON_CODE";

 export const CHECK_COUPON_CODE_SUCCEED = "CHECK_COUPON_CODE_SUCCEED";

 export const CHECK_COUPON_CODE_FAILED = "CHECK_COUPON_CODE_FAILED";

 export const ACTIVATE_MEMBERSHIP = "ACTIVATE_MEMBERSHIP";

 export const ACTIVATE_MEMBERSHIP_SUCCEED = "ACTIVATE_MEMBERSHIP_SUCCEED";

 export const ACTIVATE_MEMBERSHIP_FAILED = "ACTIVATE_MEMBERSHIP_FAILED";

 export const REDIRECT_TO_PAYMENT = "REDIRECT_TO_PAYMENT";

 export const TOGGLE_GIFT_TOASTR = "TOGGLE_GIFT_TOASTR";

 export const RESET_CHECKING_COUPON_CODE = "RESET_CHECKING_COUPON_CODE";

 export const GET_DELIVERY_PRICE = "GET_DELIVERY_PRICE";

 export const SET_DELIVERY_PRICE = "SET_DELIVERY_PRICE";
