import { SessionResponse } from "./api-types";
import fetchWithCatch from "./fetch-with-catch";

const getUserInfo = async () => {
  const { data, error } = await fetchWithCatch<SessionResponse>(() =>
    fetch("/api/auth/session", {
      cache: "no-cache",
    }),
  );

  if (!error && data && data.userProfile) {
    const { sub, email, emailVerified } = data.userProfile;

    return { sub, email, emailVerified };
  }

  throw error;
};

export default getUserInfo;
