import { put, takeEvery, call, takeLatest } from 'redux-saga/effects'
import { GET_LANDING_STATIC_CONTENT, GET_LANDING_BENEFITS, GET_TEMPLATE_VARIABLES, GET_PARTNERS, GET_FAQ } from './../../actions';
import {
  getLandingBenefitsSucceed, getLandingStaticContentSucceed, getTemplateVariablesSucceed, getLandingPartnersSucceed, getLandingFAQSucceed
} from './../../actionCreators';
import { makeAsyncCall } from '../../utils';
import { API } from '../../constants';

function* getLandingStaticContent() {
  try {
    let data = {};
    const options = {
      url: API.landing,
    };
    const result = yield call(makeAsyncCall, options)
    if (result.statusCode === 200 ) {
      data = result.body.story.content; 
      yield put(getLandingStaticContentSucceed(data))
    }
  } catch (error) {
    console.log(error);
  }
}

function* getLandingBenefits() {
  try {
    let data = {};
    const options = {
      url: API.landingBenefits,
    };
    const result = yield call(makeAsyncCall, options)
    if (result.statusCode === 200 ) {
      data = result.body.stories;
      yield put(getLandingBenefitsSucceed(data))
    }
  } catch (error) {
    console.log(error);
  }
}

function* getTemplateVariables() {
  try {
    let data:any = {};
    const options = {
      url: API.getTempalateVariables,
    };
    const result = yield call(makeAsyncCall, options)
    if (result.statusCode === 200 ) {
      result.body.story.content.template_variables.forEach((variable:any) => {
        data[variable.key] = variable.value
      });;
      yield put(getTemplateVariablesSucceed(data))
    }
  } catch (error) {
    console.log(error);
  }
}


function* getPartners() {
  try {
    let data = {};
    const options = {
      url: API.getPartners,
      method: "GET"
    };
    const result = yield call(makeAsyncCall, options)
    if (result.statusCode === 200 ) {
      data = result.body.story.content;
      yield put(getLandingPartnersSucceed(data))
    }
  } catch (error) {
    console.log(error);
  }
}

function* getFAQ() {
  try {
    let data = {};
    const options = {
      url: API.getFaq,
      method: "GET",
    };
    const result = yield call(makeAsyncCall, options)
    if (result.statusCode === 200 ) {
      data = result.body.story.content.body;
      yield put(getLandingFAQSucceed(data))
    }
  } catch (error) {
    console.log(error);
  }
}


export function* landingSaga() {
  yield takeEvery(GET_LANDING_STATIC_CONTENT, getLandingStaticContent)
  yield takeEvery(GET_LANDING_BENEFITS, getLandingBenefits)
  yield takeLatest(GET_TEMPLATE_VARIABLES, getTemplateVariables)
  yield takeLatest(GET_PARTNERS, getPartners)
  yield takeLatest(GET_FAQ, getFAQ)
}