import * as React from 'react';

class LoadingPage extends React.Component<any, any>  {
  public render() {
    const { title, subtitle } = this.props;
        return (
            <div className="loadingPage">
                <div className="loading d-flex">
                    <div className="centerItem">
                        <div className="loadingBounce">
                            <img src={require('../img/loading.gif')} width="100" alt="loading"/>
                            <div className="loadingText">
                                {title}
                                </div>
                            <div className="loadingBottom">
                              {subtitle}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoadingPage;