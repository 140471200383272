import { put, takeLatest, call } from 'redux-saga/effects'
import {
  GET_MAJOR_BENEFITS_STATIC_CONTENT,
  GET_BENEFIT_PRODUCTS,
} from '../../actions';
import {
  getMajorBenefitsStaticContentSucceed,
  getBenefitProductsSucceed,
} from '../../actionCreators';
import { makeAsyncCall } from '../../utils';
import { API } from '../../constants';

function* handleGetMajorBenefitsStaticContent() {
  try {
    const options = {
      url: API.getChooseMajorBenefitsPage,
    }
    const result = yield call(makeAsyncCall, options)
    yield put(getMajorBenefitsStaticContentSucceed(result.body.story.content))
  } catch (error) {
    console.log(error);
  }
}


function* getBenefitProducts() {
  try {
    const options = {
      url: API.getBenefits,
      method: "GET",
    }
    const result = yield call(makeAsyncCall, options)
    if (result.statusCode === 200 )
      yield put(getBenefitProductsSucceed(result.body.data))
    else 
      throw result
  } catch (e) {
    console.log(e)
  }
}

export function* benefitsSaga() {
  yield takeLatest(GET_MAJOR_BENEFITS_STATIC_CONTENT, handleGetMajorBenefitsStaticContent);
  yield takeLatest(GET_BENEFIT_PRODUCTS, getBenefitProducts);
}