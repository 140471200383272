import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import Slider from "react-slick";
import { getMajorBenefitsStaticContent, getBenefitProducts, storeUser, setSelectedBenefitProduct, getMe } from '../../actionCreators';
import moment from 'moment-with-locales-es6';

class BenefitPage extends React.Component<any, any> {
  state = {
    benefitId: null,
  }

  public componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    const { loadStaticContent, getProducts, storeData, getUser, isLoggedIn } = this.props;
    loadStaticContent();
    getProducts();

    if (isLoggedIn) {
      getUser();
    }
  }

  componentDidUpdate(prevProps: any) {
    const { userHasActiveMemberShip, history } = this.props;
    if (userHasActiveMemberShip) {
      history.push("/membership")
    }
  }

  redirectUser() {
    const { history, isLoggedIn } = this.props;
    if (isLoggedIn) {
      history.push('/checkout');
    }
    else
      history.push("/login")
  }

  public renderProducts() {
    const { products, variables, setSelectedProduct } = this.props;
    const selectedProduct = this.props.product;
    return products.map((product: any) => {
      const approved = !(product.available_from && new Date(product.available_from.replace(/-/g, '/')).getTime() >= new Date().getTime());
      return (
        <div className="sliderItem" key={product.id} onClick={() => {
          if (approved) {
            if (selectedProduct)
              setSelectedProduct(product.id === selectedProduct.id ? null : product)
            else
              setSelectedProduct(product)
          }
        }}>
          <div className="sliderItemImg">
            <img src={product.images && product.images[0]} alt="" />
          </div>
          <div className="sliderItemTitle">
            {product.title}
          </div>
          <div className="sliderItemText" dangerouslySetInnerHTML={{ __html: product.description }} />
          <div className="sliderItemData">
            {product.product_start_date && moment(product.product_start_date).locale('de').format("D. MMMM YYYY")}
          </div>
          <div className="sliderItemData">
            {product.location}
          </div>
          <div className="sliderItemLinkBox">
            <div className={"sliderItemLink" + (!approved ? " sliderItemLinkDisabled" : "") + (approved  && selectedProduct && product.id === selectedProduct.id ? " sliderItemLinkSelected" : "")} >
              {approved && selectedProduct && product.id === selectedProduct.id
              ? variables && variables.chosen.toLocaleUpperCase()
                : ( approved ?
                  (variables && variables.choose.toUpperCase())
                    : (variables && variables.coming_soon.toUpperCase())
                )
              }
              {
                selectedProduct && selectedProduct.id && product.id === selectedProduct.id ? <img className="selectedItem-checkIcon" alt="" /> : null
              }
            </div>
          </div>
          {
            product.is_hype_active && (
              <div className="eventProgress">
                <div className="eventProgressBar" style={{ width: " 80%" }}>
                  {variables && variables.tickets_left}
                </div>
              </div>
            )
          }
        </div>
      )
    })
  }

  public render() {
    const { products, variables } = this.props;
    var settings = {
      dots: false,
      infinite: false,
      initialSlide: 0,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 1,
      adaptiveHeight: true,
      variableWidth: true,
      arrows: products.length > 4 ? true : false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
            dots: false,
          }
        },
        {
          breakpoint: 768,
          settings: {
            dots: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
          }
        },
        {
          breakpoint: 600,
          settings: {
            infinite: false,
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            infinite: false,
            dots:true,
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    const { content, history, product } = this.props;
    return (
      <div className="start-benefit main-frame one-bg">
        <div className="wrapper">
          <div className="benefit-page d-flex">
            <div className="benefit-header-content">
            <div className="benefitEntryHeader">
              <div className="benefitEntryText">
                {content.title}
              </div>
              <div className="benefitEntryDesc" dangerouslySetInnerHTML={{ __html: content.body[0].body.content }}>
            </div>
            </div>
            </div>
            <div className="eventListSlider">
              <Slider {...settings}>
                {this.renderProducts()}
              </Slider>
            </div>
            <div className="navigationLinks">
              <div className="eventStarterLink d-flex">
                <div className="eventBackLink default-btn" onClick={e => {
                  e.preventDefault();
                  history.push("/")
                }}>
                  {variables && variables.back}
                </div>
                <div className="eventPrevLink primary-btn" onClick={e => {
                  e.preventDefault();
                  this.redirectUser()
                }}>
                  {variables && (product && product.id ? variables.continue : variables.choose_later)}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

const mapStateToProps = (store: any) => ({
  content: store.majorBenefits.content,
  products: store.majorBenefits.products,
  variables: store.landing.templateVariables,
  product: store.checkout.product,
  userHasActiveMemberShip: store.userAccount.userHasActiveMembership,
  id: store.userAccount.data.sub,
  isLoggedIn: store.userAccount.isLoggedIn,
});

const mapDispatchToProps = (dispatch: any) => ({
  loadStaticContent: () => dispatch(getMajorBenefitsStaticContent()),
  getProducts: () => dispatch(getBenefitProducts()),
  storeData: (data: any) => dispatch(storeUser(data)),
  setSelectedProduct: (product: any) => dispatch(setSelectedBenefitProduct(product)),
  getUser: () => dispatch(getMe()),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BenefitPage));