import {
  INITIAL_ACTION_SUCCEED,
  GET_ME,
  GET_ME_FAILED,
  GET_ME_SUCCEED,
  REDIRECT,
  GET_LOGIN_STATIC_CONTENT_SUCCEED,
  ACTIVATE_MEMBERSHIP_SUCCEED,
  REDIRECT_TO_PAYMENT,
  STORE_USER_SUCCEED,
  UPDATE_IS_LOGGED_IN,
} from '../../actions'

const userAccount = (state = {
  redirect: false,
  data: {
    content: {},
    name: "",
    body: [],
    membership_id: "",
  },
  isLoggedIn: null,
}, 
  action:any) => {
  switch (action.type) {
    case INITIAL_ACTION_SUCCEED:
      return {
        ...state,
        payload: action.payload,
      }
    case GET_ME_SUCCEED:
      return {
        ...state,
        userHasActiveMembership: action.hasActiveMembership,
        membershipId: action.membership_id,
        daysLeft: action.daysLeft,
        productUses: action.productUses,
      };
    case ACTIVATE_MEMBERSHIP_SUCCEED:
    // case REDIRECT_TO_PAYMENT:
      return {
        ...state,
        userHasActiveMembership: true,
      }
    case REDIRECT:
        return {
          ...state,
          redirect: action.redirect,
        }
    case GET_LOGIN_STATIC_CONTENT_SUCCEED:
        return {
          ...state,
          data: action.payload,
        }
    case STORE_USER_SUCCEED:
      return {
        ...state,
        user: action.payload,
      };
    case UPDATE_IS_LOGGED_IN:      
      return {
        ...state,
        isLoggedIn: action.payload
      }
    default:
      return state
  }
}

export default userAccount;
