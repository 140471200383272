import React from 'react';
import { connect } from 'react-redux';
import { createVoucherCode } from '../../actionCreators';
import { getCookie } from '../../utils';

const SubscribeButton = props => {
  const { product } = props;

  const renderButton = () => {
    const { handleGenerateCode ,variables  } = props;

    if (product.membership_id){
      return (
        <div className="rabattBtnGreen">
         {variables && variables.participated}
        </div>
      )
    }
    if (product.sold){
      return (
        <div className="hiddenEventButton">
         {variables && variables.not_available}
      </div>
    )
    }
    if (product.amount === 0 || (product.available_from && new Date(product.available_from.replace(/-/g, '/')).getTime() > new Date().getTime())) {
      return (
        <div className="hiddenEventButton">
         {variables && variables.coming_soon}
      </div>
    )
    }
    if (product.membership_id === null ){
      return (
        <div className="rabattBtn" onClick={() => handleGenerateCode(product.id)}>
          {variables && variables.participate_now}
        </div>
      )
    }
  }

  return (
    <div>
      {renderButton()}
    </div>
  )
}

const mapStateToProps = (store:any) => ({
  variables: store.landing.templateVariables,
})

const mapDispatchToProps = (dispatch: any) => ({
  handleGenerateCode: (id:number) => dispatch(createVoucherCode(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SubscribeButton);