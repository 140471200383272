import * as React from 'react';
import { AccordionComponent, AccordionItemDirective, AccordionItemsDirective } from '@syncfusion/ej2-react-navigations';
import { getLandingFAQ } from '../actionCreators';
import { connect } from 'react-redux';
import  ReactMarkdown from 'react-markdown';

class Faq extends React.Component<any, any>  {

  componentDidMount() {
    this.props.getFAQ()
  };
    
    public render() {
      const { faq } = this.props;
        return (
            <div className="faq">
                <div className="event-title">Hast du noch Fragen?</div>
                <AccordionComponent>
                    <AccordionItemsDirective>
                      {
                        faq.map((f:any) => (
                          <AccordionItemDirective header={f.key} content={() => <ReactMarkdown source={ f.body }/>} key={f._uid}/>
                        ))
                      }
                    </AccordionItemsDirective>
                </AccordionComponent>
            </div>
        );
    }
}

const mapStateToProps = (store: any) => {
  const { landing } = store;
  return {
    faq: landing.faq,
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  getFAQ: () => dispatch(getLandingFAQ()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Faq);