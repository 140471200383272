const fetchWithCatch = async <T>(fetchFn: () => Promise<Response>) => {
  let response: Response | null = null;

  try {
    response = await fetchFn();

    const contentTypeHeader = response.headers.get("Content-Type");

    const data: T =
      contentTypeHeader && contentTypeHeader.includes("application/json")
        ? await response.json()
        : await response.text();

    if (!response.ok) {
      return {
        data,
        response,
        error: new Error(`${response.statusText} - For URL: ${response.url}`),
      };
    }

    return { response, data };
  } catch (error) {
    console.error(error);

    return {
      data: null,
      response,
      error,
    };
  }
};

export default fetchWithCatch;
