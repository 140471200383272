import React, { FC } from 'react';

interface LoadingIconProps {
  id: string;
  className?: string;
  fill?: string;
}

const Spinner: FC<LoadingIconProps> = ({ className, id }) => {
  const idPrefix = id;

  return (
    <div className="spinner">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        className={`spinner-icon ${className}`}
        fill="none"
      >
        <mask id={`${idPrefix}path-1-inside-1`} fill="white">
          <path d="M12 4.97832C12 4.43801 12.4396 3.99383 12.9759 4.05974C13.9149 4.17516 14.8287 4.45656 15.6737 4.89338C16.8093 5.48041 17.7877 6.33104 18.5269 7.37399C19.2661 8.41695 19.7446 9.62186 19.9223 10.8878C20.1 12.1537 19.9718 13.4438 19.5483 14.65C19.1249 15.8562 18.4185 16.9433 17.4885 17.8204C16.5584 18.6974 15.4317 19.3388 14.2028 19.6908C12.9739 20.0427 11.6785 20.0951 10.4251 19.8435C9.4925 19.6562 8.60336 19.3047 7.79827 18.8078C7.33849 18.524 7.273 17.9025 7.61075 17.4807C7.94849 17.059 8.56132 16.999 9.03198 17.2643C9.58451 17.5758 10.1844 17.7994 10.8103 17.9251C11.7571 18.1152 12.7357 18.0757 13.664 17.8098C14.5924 17.5438 15.4435 17.0593 16.1461 16.3968C16.8487 15.7343 17.3823 14.913 17.7022 14.0019C18.0221 13.0907 18.1189 12.1161 17.9847 11.1598C17.8504 10.2035 17.4889 9.29329 16.9305 8.50542C16.3721 7.71755 15.633 7.07497 14.7752 6.63152C14.2081 6.33838 13.6001 6.13789 12.9741 6.03565C12.4408 5.94856 12 5.51863 12 4.97832Z" />
        </mask>
        <path
          d="M12 4.97832C12 4.43801 12.4396 3.99383 12.9759 4.05974C13.9149 4.17516 14.8287 4.45656 15.6737 4.89338C16.8093 5.48041 17.7877 6.33104 18.5269 7.37399C19.2661 8.41695 19.7446 9.62186 19.9223 10.8878C20.1 12.1537 19.9718 13.4438 19.5483 14.65C19.1249 15.8562 18.4185 16.9433 17.4885 17.8204C16.5584 18.6974 15.4317 19.3388 14.2028 19.6908C12.9739 20.0427 11.6785 20.0951 10.4251 19.8435C9.4925 19.6562 8.60336 19.3047 7.79827 18.8078C7.33849 18.524 7.273 17.9025 7.61075 17.4807C7.94849 17.059 8.56132 16.999 9.03198 17.2643C9.58451 17.5758 10.1844 17.7994 10.8103 17.9251C11.7571 18.1152 12.7357 18.0757 13.664 17.8098C14.5924 17.5438 15.4435 17.0593 16.1461 16.3968C16.8487 15.7343 17.3823 14.913 17.7022 14.0019C18.0221 13.0907 18.1189 12.1161 17.9847 11.1598C17.8504 10.2035 17.4889 9.29329 16.9305 8.50542C16.3721 7.71755 15.633 7.07497 14.7752 6.63152C14.2081 6.33838 13.6001 6.13789 12.9741 6.03565C12.4408 5.94856 12 5.51863 12 4.97832Z"
          stroke={`url(#${idPrefix}paint0_linear)`}
          strokeWidth="4"
          mask={`url(#${idPrefix}path-1-inside-1)`}
        />
        <defs>
          <linearGradient
            id={`${idPrefix}paint0_linear`}
            x1="14"
            y1="5.5"
            x2="12.5"
            y2="19"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#403B45" stopOpacity="0" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default Spinner;
