import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { auth } from '@energych/sdk-auth';
import BenefitPage from './pages/BenefitPage/Benefit';
import UserAccount from './pages/UserAccount/UserAccount';
import Checkout from './pages/Checkout/Checkout';
import Login from './pages/Login';
import './App.css';
import EventList from './pages/EventList/EventList';
import Backstage from './pages/Backstage/Backstage';
import { getTemplateVariables, updateIsLoggedIn, storeUser } from './actionCreators';
import Landing from './Landing/Landing';
import PrivateRoute from './hocs/PrivateRoute';
import Gift from './pages/Gift/Gift';
import getUserInfo from './lib/get-user-info';

class App extends React.Component<any, any>{

  constructor(props: any) {
    super(props);
  }

  public componentDidMount() {
    const { getVariables } = this.props;
    getVariables();
    let stage: any =  process.env.REACT_APP_SSO_ENV || "prod";
    auth.setEnvironment(stage);
    auth.initBrowser();

    getUserInfo().then((userInfo) => {
      this.props.updateIsLoggedIn(true);
      this.props.storeData({
        user: userInfo,
      })
    }).catch(() => {
      this.props.updateIsLoggedIn(false);
    });
  }


 render() {
   return (
     <Router basename={`/${process.env.REACT_APP_BASENAME}`}>
       {/* <Route exact path="/" component={MainPage} /> */}
       <Route exact path="/" component={Landing} />
       {/* <Route exact path="/main" component={MainPage} /> */}
       <Route exact path="/benefits" component={BenefitPage} />
       <Route exact path="/login" component={UserAccount} />
       <Route exact path="/auth/callback" component={Login} />
       <Route exact path="/buy-gift" component={Gift} />
       <PrivateRoute exact path="/checkout" component={Checkout} />
       <PrivateRoute exact path="/membership" component={EventList} />
       <PrivateRoute exact path="/meet-and-greet-and-studio-guides" component={Backstage} />
     </Router>
   );
 }
}

const mapStateToProps = (store: any) => ({});

const mapDisatchToProps = (dispatch:any) => ({
  getVariables: () => dispatch(getTemplateVariables()),
  storeData: (data: any) => dispatch(storeUser(data)),
  updateIsLoggedIn: (isLoggedIn: boolean) => dispatch(updateIsLoggedIn(isLoggedIn)),
});


export default connect(mapStateToProps, mapDisatchToProps)(App);