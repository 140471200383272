import * as React from 'react';
import Events from '../../src/Landing/Events';
import Faq from '../../src/Landing/Faq';

class MainSection extends React.Component<any, any> {

    public render() {
      const { benefits, content, variables, history, userHasActiveMemberShip, handleClick } = this.props;
        return (
            <div className="landing-start one-bg">
                <div className="wrapper">
                    <div className="landing-header">
                        <div className="landing-header__logo">
                            <div className="logo">
                                <img src={require("../../src/img/logo.svg")} alt="" />
                            </div>
                            <div className="entry-text" dangerouslySetInnerHTML={{ __html: content.body && content.body[0].body.content}} />
                        </div>
                        <div className="energy-label" onClick={handleClick}>
                            <div className="rotate-text">
                              {content.body.length > 1 && content.body[1].body}
                            </div>
                        </div>
                    </div>
                    <Events data={benefits} />
                    <div className="second-member">
                        <div className="event-title">Hol dir deine ONE Membership</div>
                        <div className="member-btn danger-btn" onClick={(e: React.MouseEvent<HTMLElement>) => {
                            e.preventDefault();
                            history.push(userHasActiveMemberShip ? "/membership" : "/benefits")
                        }
                        }>
                            {variables && variables.become_member}
                        </div>
                    </div>
                    <div className="type-divided d-flex">                        
                        <img src={require('../img/one_logo_white.svg')} width="100" alt="" />
                    </div>
                    <Faq />
                </div>
            </div>
        );
    }
}

export default MainSection;