import {
  GET_LANDING_BENEFITS_SUCCEED,
  GET_LANDING_BENEFIT_SUCCEED,
  GET_LANDING_STATIC_CONTENT_SUCCEED,
  GET_TEMPLATE_VARIABLES_SUCCEED,
  GET_FAQ_SUCCEED,
  GET_PARTNERS_SUCCEED,
} from '../../actions'


const landing = (state = {
  benefits: [],
  arr: [],
  partnersBlock: {
    logos: []
  },
  faq: [],
  templateVariables: defaultTemplateVariables,
  content: {
    title: "",
    content: "",
    body: [{body: ""}],
    templateVariables: defaultTemplateVariables,
  }
}, action:any) => {
  switch (action.type) {
    case "SET_CALLBACK":
        return {
          ...state,
          cb: true,
        }
    case GET_LANDING_BENEFITS_SUCCEED:
        return {
          ...state,
          benefits: action.payload,
        }
    case GET_LANDING_BENEFIT_SUCCEED:
      return {
        ...state,
        arr: action.payload,
      }
    case GET_LANDING_STATIC_CONTENT_SUCCEED:
      return {
        ...state,
        content: action.payload
      }
    case GET_TEMPLATE_VARIABLES_SUCCEED:
        return {
          ...state,
          templateVariables: action.payload,
        }
    case GET_FAQ_SUCCEED:
        return {
          ...state,
          faq: action.payload,
        }
    case GET_PARTNERS_SUCCEED:
        return {
          ...state,
          partnersBlock: action.payload,
        }
    default:
      return state
  }
}

const defaultTemplateVariables = {
  has_energy_account: "",
  no_energy_account: "",
  choose: "",
  not_available: "",
  back: "",
  choose_later: "",
  login: "",
  register: "",
  become_member: "",
  included_for_free: "",
  total: "",
  pay_now: "",
  major_benefits_title: "",
  major_benefits_subtitle: "",
  chosen: "",
  welcome_gift_title: "",
  welcome_gift_subtitle: "",
  meet_and_greets_title: "",
  meet_and_greets_subtitle: "",
  meet_and_greets_go_to: "",
  studio_guides_title: "",
  studio_guides_subtitle: "",
  studio_guides_go_to: "",
  product_voucher_code: "",
  major_benefit_change_title: "",
  major_benefit_change_subtitle: "",
  cancel: "",
  confirm: "",
  tickets_left: "",
  product_voucher_copy_code: "",
  go_to_product: "",
  product_voucher_copied_code: "",
  won: "",
  participated: "",
  welcome_gift_received: "",
  winner_get_notified: "",
  major_benefits_chosen_description: "",
  major_benefits_change_possible: "",
  major_benefits_choose_nowe: "",
  major_benefit_issued_description: "",
  major_benefits_chosen_description_change_possible: "",
  coming_soon: ""
}

export default landing;