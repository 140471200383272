import {all} from 'redux-saga/effects' ;
import { userAccountSaga } from './pages/UserAccount/saga';
import { benefitsSaga } from './pages/BenefitPage/saga';
import { landingSaga } from './pages/MainPage/saga';
import { checkoutSaga } from './pages/Checkout/saga';
import { membershipHomeSaga } from './pages/EventList/saga';
import { giftSaga } from './pages/Gift/saga';

export default function* rootSaga () {
  yield all([
    userAccountSaga(),
    benefitsSaga(),
    landingSaga(),
    checkoutSaga(),
    membershipHomeSaga(),
    giftSaga(),
  ])
}