import { put, takeLatest, takeEvery, call, select } from 'redux-saga/effects'
import {
  GET_MEMBERSHIP_HOME_MAJOR,
  GET_MEMBERSHIP_HOME_VAUCHER,
  GET_MEMBERSHIP_HOME_MG,
  GET_MEMBERSHIP_HOME_SG,
  CREATE_VOUCHER_CODE,
  CHANGE_MAJOR_BENEFIT,
  ADD_MEMBERSHIP,
  DELETE_MEMBERSHIP,
  GET_MEMBERSHIP_HOME_WG,
  GET_MEMBERSHIP_HOME,
  GET_MEMBERSHIP_HOME_STATIC,
  RENEW_MEMBERSHIP,
} from '../../actions';
import {
  getMembershipHomeMajorSucceed,
  getMembershipHomeMajorFailed,
  getMembershipHomeVaucherSucceed,
  getMembershipHomeVaucherFailed,
  getMembershipHomeMGSucceed,
  getMembershipHomeMGFailed,
  getMembershipHomeSGSucceed,
  getMembershipHomeSGFailed,
  createVoucherCodeSucceed,
  createVoucherCodeFailed,
  changeBenefitSucceed,
  changeBenefitFailed,
  getMembershipHome,
  getMembershipHomeMajor,
  getMembershipHomeVaucher,
  getMembershipHomeMG,
  getMembershipHomeSG,
  getMembershipHomeWGSucceed,
  getMembershipHomeStaticSucceed,
  getMembershipHomeWiningChanceSucceed,
  getMembershipHomeReferenceCount,
  renewMembershipSucceed,
  renewMembershipFailed,
  getMe,
} from '../../actionCreators';
import { makeAsyncCall, getCookie } from '../../utils';
import { API } from '../../constants';



function* getMajors({payload} :any) {
  try {
    const options = {
      url: API.getMembershipHomeMajor,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload)
    }
    const result = yield call(makeAsyncCall, options)
    if (result.statusCode === 200 ) {
      yield put(getMembershipHomeMajorSucceed(result.body.data));
    }
    else 
      yield put(getMembershipHomeMajorFailed([]))
  } catch (e) {
    console.log(e)
  }
}


function* getVauchers() {
  try {
    const options = {
      url: API.getMembershipHomevoucher,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({})

    }
    const result = yield call(makeAsyncCall, options)
    if (result.statusCode === 200 )
      yield put(getMembershipHomeVaucherSucceed(result.body.data))
    else 
      yield put(getMembershipHomeVaucherFailed(result.body.data))
  } catch (e) {
    console.log(e)
  }
}

function* getMG(action: any) {
  try {
    const options = {
      url: API.getMembershipHomeMeetAndGreet,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({product_type_id: action.payload.type})
    }
    const result = yield call(makeAsyncCall, options)
    if (result.statusCode === 200 ){
      let data :any[]= [];
      result.body.data.forEach((d:any) => {
        if ( !data.find((item:any) => item.id === d.id))
          data.push(d);
      });
      yield put(getMembershipHomeMGSucceed(data))
    }
    else 
      yield put(getMembershipHomeMGFailed(result.body.data))
  } catch (e) {
    console.log(e)
  }
}

function* getSG(action:any) {
  try {
    const options = {
      url: API.getMembershipHomeStudioGuide,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({product_type_id: action.payload.type})
    }
    const result = yield call(makeAsyncCall, options)
    if (result.statusCode === 200 ){
      let data :any[]= [];
      result.body.data.forEach((d:any) => {
        if ( !data.find((item:any) => item.id === d.id))
          data.push(d);
      });
      yield put(getMembershipHomeSGSucceed(data))
    }
    else 
      yield put(getMembershipHomeSGFailed(result.body.data))
  } catch (e) {
    console.log(e)
  }
}


function* getWelcomeGifts(action:any) {
  try {
    const options = {
      url: API.getMembershipHomeWelcomeGifts,
      method: "GET"
    }
    const result = yield call(makeAsyncCall, options)
    if (result.statusCode === 200 ){
      yield put(getMembershipHomeWGSucceed(result.body.data))
    }
  } catch (e) {
    console.log(e)
  }
}

function* createCode({id}:any) {
  try {
    const options = {
      url: API.demand(id),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({})
    }
    const result = yield call(makeAsyncCall, options)
    if (result.statusCode === 200 ){
      yield put(getMembershipHome());
      yield put(createVoucherCodeSucceed(id))
    }
    else
      yield put(createVoucherCodeFailed(id))

  } catch (e) {
    console.log(e)
  }
}

function* changeBenefit({payload}: any) {
  const options = {
    url: API.changeBenefit,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...payload,
    })
  };
  try {

    const result = yield call(makeAsyncCall, options)
    if ([200, 201].includes(result.statusCode)) {
      yield put(changeBenefitSucceed(result.body.data))
      yield put(getMembershipHomeMajor());
      yield put(getMe()); // Get updated ProductUses
    } else {
      yield put(changeBenefitFailed(result.body.data));
    }
  } catch (e) {
    console.log(e)
  }
}


function* addMembership(action: any) {
  try {
    const options = {
      url: API.toggleMembership(action.id),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({})
    }
    const result = yield call(makeAsyncCall, options)
    if (result.statusCode === 200 || result.statusCode === 201 ){
      if (action.isBackStage)
        yield put(getMembershipHomeMG({ type: 4}))
      else 
        yield put (getMembershipHomeSG({ type: 5 }))
    }
  } catch (e) {
    console.log(e)
  }
}

function* deleteMembership( action: any) {
  try {
    const options = {
      url: API.toggleMembership(action.id),
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({})
    }
    const result = yield call(makeAsyncCall, options)
    if (result.statusCode === 200 || result.statusCode === 201 ){
      if (action.isBackStage)
        yield put(getMembershipHomeMG({ type: 4}))
      else 
        yield put (getMembershipHomeSG({ type: 5 }))
    }
  } catch (e) {
    console.log(e)
  }
}


function* getMembershipData() {
  try {
    const options = {
      url: API.getMembershipHome,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({})
    }
    const result = yield call(makeAsyncCall, options)
    if (result.statusCode === 200 ) {
      const voucherProducts = result.body.data['voucher-products'];
      let data: any = [];
      for (var i=0; i<voucherProducts.length; i++) {
        if (data.find(item => item.id === voucherProducts[i].id)){
          data = data.filter(item => item.id !== voucherProducts[i].id || (item.id === voucherProducts[i].id && item.membership_id));
        }
        else
          data.push(voucherProducts[i])
      }
      const meetAndGreeds = result.body.data['meets-and-greets'];
      let data3:any = [];
      for (var i=0; i<meetAndGreeds.length; i++) {
        if ( data3.find(item => item.id === meetAndGreeds[i].id)){
          data3 = data3.filter(item => item.id !== meetAndGreeds[i].id || (item.id === meetAndGreeds[i].id && item.membership_id));
        }
        else {
          data3.push(meetAndGreeds[i])
        }
      }
      const studioGuides = result.body.data['studio-guides'];
      let data4:any = [];
      for (var i=0; i<studioGuides.length; i++) {
        if ( data4.find(item => item.id === studioGuides[i].id)){
          data4 = data4.filter(item => item.id !== studioGuides[i].id || (item.id === studioGuides[i].id && item.membership_id));
        }
        else {
          data4.push(studioGuides[i])
        }
      }
      const referenceCount = result.body.data['reference-count'];
      yield put(getMembershipHomeMajorSucceed(result.body.data['major-benefits']))
      yield put(getMembershipHomeVaucherSucceed(data))
      yield put(getMembershipHomeMGSucceed(data3))
      yield put(getMembershipHomeSGSucceed(data4))
      yield put(getMembershipHomeWGSucceed(result.body.data['welcome-gifts']))
      yield put(getMembershipHomeWiningChanceSucceed(result.body.data['winning-chance']))
      yield put(getMembershipHomeReferenceCount(referenceCount))
    }
    else 
      yield put(getMembershipHomeMajorFailed([]))
  } catch (e) {
    console.log(e)
  }
}


function* getStatic() {
  try {
    const options = {
      url: API.getMembershipStatic,
      method: "GET",
    }
    const result = yield call(makeAsyncCall, options)
    if (result.statusCode === 200 ) {
      yield put(getMembershipHomeStaticSucceed(result.body.story.content))
    }
  } catch (e) {
    console.log(e)
  }
}

function* renewMembershipSaga() {
  const store = yield select();
  const body = {
    failure_url: `${window.location.origin}/${process.env.REACT_APP_BASENAME}/membership`,
    success_url: `${window.location.origin}/${process.env.REACT_APP_BASENAME}/membership`,
    user: {
      id: store.userAccount.user.sub,
      email: store.userAccount.user.email,
    },
    plan_price_id: 1,
    product_ids: [],
  };
  const options = {
    url: API.generateInvoice,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...body,
    }),
  }

  try {
    const result = yield call(makeAsyncCall, options);

    if (result.statusCode === 200) {
      yield put(renewMembershipSucceed());

      window.location.href = result.body.data;
    }
  } catch (error) {
    console.error(error);

    yield put(renewMembershipFailed(error));
  }
}


export function* membershipHomeSaga() {
  yield takeLatest(GET_MEMBERSHIP_HOME_MAJOR, getMajors);
  yield takeLatest(GET_MEMBERSHIP_HOME_VAUCHER, getVauchers);
  yield takeLatest(GET_MEMBERSHIP_HOME_MG, getMG);
  yield takeLatest(GET_MEMBERSHIP_HOME_SG, getSG);
  yield takeLatest(CREATE_VOUCHER_CODE, createCode);
  // NOTE (damian): takeEvery to allow remove more than one major benefit at once
  yield takeEvery(CHANGE_MAJOR_BENEFIT, changeBenefit);
  yield takeLatest(ADD_MEMBERSHIP, addMembership);
  yield takeLatest(DELETE_MEMBERSHIP, deleteMembership);
  yield takeLatest(GET_MEMBERSHIP_HOME_WG, getWelcomeGifts);
  yield takeLatest(GET_MEMBERSHIP_HOME, getMembershipData);
  yield takeLatest(GET_MEMBERSHIP_HOME_STATIC, getStatic);
  yield takeLatest(RENEW_MEMBERSHIP, renewMembershipSaga);
}
