import * as React from 'react';
import { auth } from '@energych/sdk-auth';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class Callback extends React.PureComponent<any> {
  state = {
    failed: false,
    message: null,
  };

  componentDidMount() {
    auth.parseHash().then((redirectTo) => {
      this.props.setCallback()
      window.location.href = redirectTo;
    }).catch((err) => {
      this.setState({ failed: true, message: err.message });
    });

    setTimeout(() => {
      if (!this.state.failed) {
        this.setState({ failed: true, message: 'Request timed out' });
      }
    }, this.props.loginTimeout || 10000);
  }

  render() {
    const { message }  = this.props;
    if (this.state.failed && this.props.failed) {
      return <p>{message}</p>;
    }
    return this.props.children;
  }
}

const mapStateToProps = (store: any) => ({})

const mapDisaptchToProps = (dispatch: any) => ({
  setCallback: () => dispatch({ type: "SET_CALLBACK"})
});

export default connect(mapStateToProps, mapDisaptchToProps)(withRouter(Callback));
