interface RelativeUrl {
  pathname: string;
  search: string;
  hash: string;
}

const getRelativeUrl = (url: RelativeUrl) =>
  `${url.pathname}${url.search}${url.hash}`;

const attachRedirectPage = (relativeUrl: string, redirectPage?: string) => {
  const url = new URL(`https://example.com${relativeUrl}`);

  const finalRedirectPage = redirectPage
    ? `/${process.env.REACT_APP_BASENAME}${redirectPage}`
    : getRelativeUrl(window.location);

  url.searchParams.append("redirect_page", finalRedirectPage);

  return getRelativeUrl(url);
};

const getLoginUrl = (redirectPage?: string) =>
  attachRedirectPage("/api/authorize", redirectPage);

export default getLoginUrl;
