import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getMembershipHomeMG, getMembershipHomeSG, addMembership, deleteMemberShip } from '../../actionCreators';
import CodeButton from '../../components/CodeButton';
import SubscribeButton from '../../components/SubscribeButton';
import { SHOPIFY_ACTION_ID, UPLOADED_DISCOUNT_ACTION_ID } from '../../constants';

class Backstage extends React.Component<any, any> {


  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  }

    public render() {
      const { meetGreets, studioGuides, history, variables } = this.props;
        return (
            <div className="backstage start-backstage main-frame one-bg">
                <div className="wrapper">
                    <div className="backToPage">
                        <div className="backToPageLink" onClick={() => history.push("/membership")}>
                          {variables && variables.back}
                        </div>
                    </div>
                    <div className="divider d-flex">
                      <div className="divider-text">{studioGuides.length ? variables.studio_guides_title : variables.no_studioguides_available}</div>
                      <div className="divider-one-log">
                        <img src={require('../../img/one_logo_white.svg')} width="100"  alt="" />
                      </div>
                    </div>
                    {
                      studioGuides.map( (d:any, i:number) =>(
                        <React.Fragment key={d.id}>
                          <div className="backstageInfoBlock">
                              <div className="infoBlockItem d-flex">
                                  <div className="leftInfoBlock">
                                      <div className="infoBlockHeader">
                                          {d.title} {/* - BACKSTAGE PASS */}
                                      </div>
                                      <div className="locationDate">
                                          <div className="infoLocation">{d.location}</div>
                                          {/* <div className="infoDate">{moment(d.product_start_date).format("DD. MMMM YYYY")}</div> */}
                                      </div>
                                      <div className="infoBlockContent" dangerouslySetInnerHTML={{__html: d.description}} />
                                      <div className="clickToEventButton">
                                      {
                                        new Date((d.available_from || "").replace(/-/g, '/')).getTime() > new Date().getTime() ? (
                                        <div className="hiddenEventButton">
                                          {variables && variables.coming_soon}
                                        </div>
                                        ): (
                                          d.product_action_id === SHOPIFY_ACTION_ID ||  d.product_action_id === UPLOADED_DISCOUNT_ACTION_ID? (
                                            <CodeButton  product={d} />
                                          ) : <SubscribeButton product={d} />
                                        )
                                      }
                                      </div>
                                      {/* <div className="emailNatification">
                                          Die Gewinner werden per E-Mail informiert
                                      </div> */}
                                  </div>
                                  <div className="rightInfoBlock">
                                      <div className="infoBlockImg">
                                          <img src={d.images &&  d.images[0]} alt="" />
                                      </div>
                                  </div>
                              </div>
                          </div>
                          {
                            i < studioGuides.length-1 && (
                              <div className="thunderLine d-flex">
                                  <div className="thunder1"></div>
                                  <div className="thunder2"></div>
                              </div>
                            )
                          }
                        </React.Fragment>
                      ))
                    }
                    <div className="divider d-flex">
                      <div className="divider-text">{meetGreets.length ?  variables.meet_and_greets_title : variables.no_meetandgreets_available }</div>
                      <div className="divider-one-log">
                        <img src={require('../../img/one_logo_white.svg')} width="100"  alt="" />
                      </div>
                    </div>
                    {
                      meetGreets.map( (d:any, i:number) =>(
                        <React.Fragment key={d.id}>
                          <div className="backstageInfoBlock">
                              <div className="infoBlockItem d-flex">
                                  <div className="leftInfoBlock">
                                      <div className="infoBlockHeader">
                                          {d.title} {/* - BACKSTAGE PASS */}
                                      </div>
                                      <div className="locationDate">
                                          <div className="infoLocation">{d.location}</div>
                                          {/* <div className="infoDate">{moment(d.product_start_date).format("DD. MMMM YYYY")}</div> */}
                                      </div>
                                      <div className="infoBlockContent" dangerouslySetInnerHTML={{__html: d.description}} />
                                      <div className="clickToEventButton">
                                      {
                                        new Date((d.available_from || "").replace(/-/g, '/')).getTime() > new Date().getTime() ? (
                                        <div className="hiddenEventButton">
                                          {variables && variables.coming_soon}
                                        </div>
                                        ): (
                                          d.product_action_id === SHOPIFY_ACTION_ID || d.product_action_id === UPLOADED_DISCOUNT_ACTION_ID ? (
                                            <CodeButton  product={d} />
                                          ) : <SubscribeButton product={d} />
                                        )
                                      }
                                      </div>
                                      {/* <div className="emailNatification">
                                          Die Gewinner werden per E-Mail informiert
                                      </div> */}
                                  </div>
                                  <div className="rightInfoBlock">
                                      <div className="infoBlockImg">
                                          <img src={d.images &&  d.images[0]} alt="" />
                                      </div>
                                  </div>
                              </div>
                          </div>
                          {
                            i < meetGreets.length-1 && (
                              <div className="thunderLine d-flex">
                                  <div className="thunder1"></div>
                                  <div className="thunder2"></div>
                              </div>
                            )
                          }
                        </React.Fragment>
                      ))
                    }
                </div>
            </div>
        );
    }


}


const mapStateToProps = (store:any) => ({
  variables: store.landing.templateVariables,
  meetGreets: store.membershipHome.meetGreets,
  studioGuides: store.membershipHome.studioGuides,
})

const mapDispatchToProps = (dispatch: any) => ({
  getMeetGreets: (payload: { type: number}) => dispatch(getMembershipHomeMG(payload)),
  getStudioGuides: (payload: {type: number}) => dispatch(getMembershipHomeSG(payload)),
  addMemberShipProduct: (id: number, isBackStage: boolean) => dispatch(addMembership(id, isBackStage)),
  deleteMembershipProduct: (id: number, isBackStage: boolean) => dispatch(deleteMemberShip(id, isBackStage)),
});

export default connect(mapStateToProps, mapDispatchToProps)( withRouter(Backstage));