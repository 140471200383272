import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getPlanPrice, getMe, getCheckoutStaticContent, generateGiftInvoice, toggleGiftToastr, getDeliveryPrice } from '../../actionCreators';

class Gift extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      checked2: true,
      product: {
        id: null
      },
      loading: false,
      recipient_address: null,
      pdf_send_to: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }


  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    const { getPrice, getContent, getDeliveryPrice } = this.props;
    getPrice();
    getContent();
    getDeliveryPrice();
  }

  componentDidUpdate(prevProps: any) {
    const { invoice } = this.props;
    const prevInvoice = prevProps.invoice;
    const { userHasActiveMemberShip, history } = this.props;
    if (invoice !== prevInvoice)
      window.location.href = invoice;
  }


  handleChange() {
    const currentState = !this.state.checked;
    if (!currentState)
      this.setState({ recipient_address: null })
    this.setState({
      checked: currentState,
      checked2: false
    });

  }

  handleChange2() {
    const currentState = !this.state.checked2;
    const { checked } = this.state;
    if (!currentState)
      this.setState({ pdf_send_to: null })
    this.setState({
      checked2: currentState,
      checked: false,
    })
  }

  handleInputChange(e) {
    this.setState({ recipient_address: { ... this.state.recipient_address, [e.target.name]: e.target.value } })
  }

  generateInvoice() {
    this.setState({ loading: true })
    const { planPrice, createGiftInvoice } = this.props;
    const { product, recipient_address, pdf_send_to } = this.state;
    let redirectTo = `${window.location.origin}/${process.env.REACT_APP_BASENAME}/`;
    const body = {
      success_url: redirectTo,
      failure_url: redirectTo,
      plan_price_id: planPrice.id,
      recipient_address,
      pdf_send_to
    };
    createGiftInvoice(body);
  }

  render() {
    const hidden = this.state.checked ? "d-block" : "hidden";
    const hidden2 = this.state.checked2 ? "d-block" : "hidden";

    const { product, loading, checked, checked2 } = this.state;
    const { planPrice, history, variables, content, deliveryPrice } = this.props;

    var d = new Date();
    var planDate = new Date(d.getFullYear() + 1, d.getMonth(), d.getDate() - 1);

    return (
      <div className="checkout main-frame one-bg">
        <div className="wrapper">
          <div className="checkoutArea">
            <div className="checkoutHeader">
              <div className="checkoutHeaderTitle">
                {content && content.title}
              </div>
              <div className="checkoutHeaderText" dangerouslySetInnerHTML={{ __html: content && content.body && content.body[0].body.content }} />
            </div>
            <div className="checkoutItem">
              <div className="checkoutItemRow d-flex">
                <div className="productLogo">
                  <img src={require('../../img/33.png')} alt="" />
                </div>
                <div className="checkoutTwo d-flex">
                  <div className="aboutProduct">
                    <div className="productName">
                      {variables && variables.membership_checkout_gift_title}
                    </div>
                    <div className="checkoutProductText">
                      <div className="include-text">
                        {planPrice && planPrice.description}
                      </div>
                    </div>
                    <div className="checkoutProductText d-flex">
                      <div className="gift-box" />
                      <div className="include-text">
                        Membership als Geschenk
                                </div>
                    </div>
                  </div>
                  <div className="priceToNow d-flex">
                    <div className="productPrice">
                      CHF  {planPrice && planPrice.price}
                    </div>
                    <div className="productFavorite">
                      <button className="addFavorite">
                        <span>{variables && variables.chosen}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gift-options">
              <div className="checkoutHeaderTitle">
                Geschenk Optionen
                          </div>
              <div className="gift-options-row">
                <div className="gf-elements d-flex">
                  <div className="gf-voucher">
                    <span className="gf-v-title">Voucher als Geschenkkarte verschenken</span>
                    <span className="gf-v-title">Lieferdauer: 2 Tage</span>
                  </div>
                  <div className="gf-price productPrice">
                    CHF {deliveryPrice}
                  </div>
                  <div className="gf-check">
                    <input type="radio"
                      name="radio_1"
                      checked={this.state.checked}
                      onChange={this.handleChange}
                      className="checkbox"
                      id="ch_1" />
                    <label htmlFor="ch_1" />
                  </div>
                </div>
                <div className={hidden} >
                  <div className="gf-address d-flex">
                    <div className="gf-address-title">
                      Adresse vom Empfänger
                                    </div>
                    <div className="gf-address-inputs">
                      <div className="gf-inputs-base d-flex">
                        <div className="gf-inputs-row d-flex">
                          <label className="gf-label">Vorname</label>
                          <input type="text" name="first_name" className="sp-input" onChange={this.handleInputChange} />
                        </div>
                        <div className="gf-inputs-row d-flex">
                          <label className="gf-label">Nachname</label>
                          <input type="text" name="last_name" className="sp-input" onChange={this.handleInputChange} />
                        </div>
                      </div>
                      <div className="gf-inputs-base d-flex">
                        <div className="gf-inputs-row d-flex">
                          <label className="gf-label">Strasse & hausnummer</label>
                          <input type="text" name="street" className="sp-input" onChange={this.handleInputChange} />
                        </div>
                      </div>
                      <div className="gf-inputs-base d-flex">
                        <div className="gf-inputs-row d-flex">
                          <label className="gf-label">Postleitzahl</label>
                          <input type="text" name="postal_code" className="sp-input" onChange={this.handleInputChange} />
                        </div>
                        <div className="gf-inputs-row d-flex">
                          <label className="gf-label">Stadt</label>
                          <input type="text" name="city" className="sp-input" onChange={this.handleInputChange} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="gift-options-row">
                <div className="gf-elements d-flex">
                  <div className="gf-voucher">
                    <span className="gf-v-title">{variables && variables.voucher_as_email}</span>
                  </div>
                  <div className="fr-gift-row">
                    <div className="fr-gift">
                      Kostenlos
                                   </div>
                  </div>
                  <div className="gf-check">
                    <input type="radio"
                      name="radio_1"
                      checked={checked2}
                      onChange={this.handleChange2}
                      className="checkbox"
                      id="ch_2" />
                    <label htmlFor="ch_2" />
                  </div>
                </div>
                <div className={hidden2}>
                  <div className="gf-address-inputs">
                    <div className="gf-inputs-base d-flex">
                      {/* <div className="gf-inputs-row d-flex">
                        <label className="gf-label">Empfänger e-mail</label>
                        <input type="text" name="email" className="sp-input" onChange={(e) => this.setState({ pdf_send_to: e.target.value })} />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="thunderLine d-flex">
                <div className="thunder1"></div>
                <div className="thunder2"></div>
              </div>
            </div>
            <div className="totalPrice d-flex">
              <div className="totalNumber d-flex">
                <div className="endTotal">
                  {variables && variables.total}
                </div>
                <div className="endPrice">
                  {planPrice && (checked ?  (+planPrice.price + deliveryPrice)+ ".00" : planPrice.price)}
                </div>
              </div>
            </div>
            <div className="eventStarterLink d-flex">
              <div className="eventBackLink default-btn" onClick={() => history.push("/")}>
                {variables && variables.back}
              </div>
              <div className="gf-coupon-code d-flex">
                <div className="eventPrevLink primary-btn" onClick={(e: any) => this.generateInvoice()}>
                  {variables && variables.pay_now}
                </div>
              </div>
            </div>
            <div className="onMobile d-flex">
              <div className="eventPrevLink" onClick={(e: any) => this.generateInvoice()}>
                {variables && variables.pay_now}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: any) => ({
  planPrice: store.checkout.planPrice,
  invoice: store.giftInvoice.invoice,
  product: store.checkout.product,
  variables: store.landing.templateVariables,
  userHasActiveMemberShip: store.userAccount.userHasActiveMembership,
  content: store.checkout.content,
  deliveryPrice: store.giftInvoice.deliveryPrice,
})

const mapDispatchToProps = (dispatch: any) => ({
  getPrice: () => dispatch(getPlanPrice()),
  createGiftInvoice: (data: any) => dispatch(generateGiftInvoice(data)),
  getUser: () => dispatch(getMe()),
  getContent: () => dispatch(getCheckoutStaticContent()),
  toggleGiftToastr: () => dispatch(toggleGiftToastr()),
  getDeliveryPrice: () => dispatch(getDeliveryPrice()),
});


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Gift));