import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga'
import { mergeDeepRight } from 'ramda';

import rootReducer from './reducers';
import rootSaga from './sagas';
import { loadState, saveState } from './localStorage';

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();
const devtool = (window as any).__REDUX_DEVTOOLS_EXTENSION__ && process.env.NODE_ENV === 'development' ? (window as any).__REDUX_DEVTOOLS_EXTENSION__() : (f:any) => f;
const composedEnhancers = compose(
  applyMiddleware(sagaMiddleware),
  devtool
);

const persistStore = loadState() || {};

const defaultState = rootReducer(undefined, {
  type: undefined,
});

const stateWithPersistStore = mergeDeepRight(defaultState, persistStore);

export const store = createStore(
  rootReducer,
  stateWithPersistStore,
  composedEnhancers
  )




store.subscribe(() => {
  saveState({
    checkout: {
      content: {},
      couponCode: null,
      planPrice: store.getState().checkout.planPrice,
      product: store.getState().checkout.product,
      invoice: store.getState().checkout.invoice,
    },
    giftInvoice: {
      showGiftToastr: store.getState().giftInvoice.showGiftToastr,
    },
    userAccount: {
      data: { content: ""},
      redirect: store.getState().userAccount.redirect,
      isLoggedIn: null,
    },
    membershipHome: {
      ...store.getState().membershipHome,
    }
  });
});
sagaMiddleware.run(rootSaga);