import { put, takeLatest, call } from 'redux-saga/effects'
import {
  GET_PRICE_PLAN, GENERATE_INVOICE, GET_CHECKOUT_STATIC_CONTENT, CHECK_COUPON_CODE, ACTIVATE_MEMBERSHIP,
} from '../../actions';
import {
  getPlanPriceSucceed, generateInvoiceSucceed, generateInvoiceFailed, getCheckoutStaticContentSucceed, checkCouponCodeSucceed, checkCouponCodeFailed, activateMembershipSucceed, activateMembershipFailed,
  redirectToPayment,
} from '../../actionCreators';
import { makeAsyncCall } from '../../utils';
import { API } from '../../constants';


function* getBenefitProducts() {
  try {
    const options = {
      url: API.getPricePlan,
      method: "GET"
    }
    const result = yield call(makeAsyncCall, options)
    if (result.statusCode === 200 )
      yield put(getPlanPriceSucceed(result.body.data))
    else 
      throw result
  } catch (e) {
    console.log(e)
  }
}

function* createInvoice({ payload }:any) {
  try {
    const options = {
      url: API.generateInvoice,
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json"
      }
    }
    const result = yield call(makeAsyncCall, options)
    if (result.statusCode === 200 || result.statusCode === 201 )
      yield put(generateInvoiceSucceed(result.body.data))
    else 
      yield put(generateInvoiceFailed(result.data.body))
  } catch (e) {
    console.log(e)
  }
}


function* getCheckoutContent(action:any) {
  try {
    const options = {
      url: API.getCheckout,
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
    }
    const result = yield call(makeAsyncCall, options)
    if (result.statusCode === 200 ){
      yield put(getCheckoutStaticContentSucceed(result.body.story.content))
    }
  } catch (error) {
    console.log(error);
  }
}

function* checkCouponCode(action) {
  try {
    const options = {
      url: API.checkCouponCode,
      method: "POST",
      body: JSON.stringify(action.payload),
      headers: {
        "Content-Type": "application/json"
      },
    }
    const result = yield call(makeAsyncCall, options)
    if (result.statusCode === 200 ){
      if (result.body.data)
        yield yield put(redirectToPayment(result.body.data.payment_url, result.body.data.new_price ))
      else yield put(checkCouponCodeSucceed(result.body))
    } else {
      yield put(checkCouponCodeFailed(result.body))
    }
  } catch (error) {
    console.log(error);
  }
}


function* activateMembership(action) {
  try {
    const options = {
      url: API.activateMembership,
      method: "POST",
      body: JSON.stringify(action.payload),
      headers: {
        "Content-Type": "application/json"
      },
    }
    const result = yield call(makeAsyncCall, options)
    if (result.statusCode === 200 ){
      yield put(activateMembershipSucceed())
    } else {
      yield put(activateMembershipFailed())
    }
  } catch (error) {
    console.log(error);
  }
}

export function* checkoutSaga() {
  yield takeLatest(CHECK_COUPON_CODE, checkCouponCode)
  yield takeLatest(GET_PRICE_PLAN, getBenefitProducts);
  yield takeLatest(GENERATE_INVOICE, createInvoice)
  yield takeLatest(GET_CHECKOUT_STATIC_CONTENT, getCheckoutContent)
  yield takeLatest(ACTIVATE_MEMBERSHIP, activateMembership)
}