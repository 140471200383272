import { put, takeLatest, call } from 'redux-saga/effects'
import {
  GET_PRICE_PLAN, GENERATE_INVOICE, GET_CHECKOUT_STATIC_CONTENT, GENERATE_GIFT_INVOICE, GET_DELIVERY_PRICE,
} from '../../actions';
import {
  getPlanPriceSucceed, generateInvoiceSucceed, generateInvoiceFailed, getCheckoutStaticContentSucceed, generateGiftInvoiceSucceed, generateGiftInvoiceFailed, getDeliveryPrice, setDeliveryPrice,
} from '../../actionCreators';
import { makeAsyncCall } from '../../utils';
import { API } from '../../constants';


function* createInvoice({ payload }:any) {
  try {
    const options = {
      url: API.generateGiftInvoice,
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json"
      }
    }
    const result = yield call(makeAsyncCall, options)
    if (result.statusCode === 200 || result.statusCode === 201 )
      yield put(generateGiftInvoiceSucceed(result.body.data))
    else 
      yield put(generateGiftInvoiceFailed(result.data.body))
  } catch (e) {
    console.log(e)
  }
}

function* getDeliveryPriceQuery({ payload }:any) {
  try {
    const options = {
      url: API.getDeliveryPrice,
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    }
    const result = yield call(makeAsyncCall, options)
    if (result.statusCode === 200  )
      yield put(setDeliveryPrice(result.body.data))
  } catch (e) {
    console.log(e)
  }
}


export function* giftSaga() {
  yield takeLatest(GENERATE_GIFT_INVOICE, createInvoice)
  yield takeLatest(GET_DELIVERY_PRICE, getDeliveryPriceQuery)
}