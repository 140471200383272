import {
  GET_MAJOR_BENEFITS_STATIC_CONTENT_SUCCEED,
  GET_BENEFIT_PRODUCTS_SUCCEED,
} from '../../actions'


const majorBenefits = (state = {
  products: [],
  content: {
    title: "",
    body: [
      {
        body: {
          content: ""
        }
      }
    ]
  }
}, action:any) => {
  switch (action.type) {
    case GET_MAJOR_BENEFITS_STATIC_CONTENT_SUCCEED:
      return {
        ...state,
        content: action.payload,
      }
    case GET_BENEFIT_PRODUCTS_SUCCEED:
        return {
          ...state,
          products: action.payload,
        }
    default:
      return state
  }
}

export default majorBenefits;