const STORYBLOK_API_URL = 'https://api.storyblok.com/v1/cdn/spaces/';
const spaceId = 57096;

export const WEBHOOK_ACTION_ID = 1;
export const SHOPIFY_ACTION_ID = 2;
export const UPLOADED_DISCOUNT_ACTION_ID = 3;
export const SEND_SUBSCRIBER_EMAIL_ACTION_ID = 4;
const stories = {
  landingPage: 1347366,
  chooseMajorBenefits: 1347368,
  login: 1347369,
  seiten: 1323665,
  membership: 1347370,
  landingBenefits: '?with_parent=1323679',
  templateVariables: 1329906,
  faq: 1761132,
  partners: 1323786,
  checkout: 1812456,
}

export const API = {
  activateMembership: `${process.env.REACT_APP_API_URL}client/v1/activate-coupon-membership`,
  toggleMembership: id => `${process.env.REACT_APP_API_URL}client/v1/membership/product/${id}`,
  getChooseMajorBenefitsPage: `${STORYBLOK_API_URL}${spaceId}/stories/${stories.chooseMajorBenefits}?token=${process.env.REACT_APP_STORYBLOK_TOKEN}&timestamp=${Date.now()}`,
  products: `${process.env.REACT_APP_API_URL}client/v1/products?product_type_id=5`,
  getMembershipHome: `${process.env.REACT_APP_API_URL}client/v1/membership-home`,
  getMembershipHomeMajor: `${process.env.REACT_APP_API_URL}client/v1/membership-home-major`,
  getMembershipHomevoucher: `${process.env.REACT_APP_API_URL}client/v1/membership-home-voucher-products`,
  getMembershipHomeMeetAndGreet: `${process.env.REACT_APP_API_URL}client/v1/membership-home-product-type`,
  getMembershipHomeWelcomeGifts: `${process.env.REACT_APP_API_URL}client/v1/membership-home-welcome-gifts`,
  getFaq: `${STORYBLOK_API_URL}${spaceId}/stories/${stories.faq}?token=${process.env.REACT_APP_STORYBLOK_TOKEN}&timestamp=${Date.now()}`,
  getLogin: `${STORYBLOK_API_URL}${spaceId}/stories/${stories.login}?token=${process.env.REACT_APP_STORYBLOK_TOKEN}&timestamp=${Date.now()}`,
  getCheckout: `${STORYBLOK_API_URL}${spaceId}/stories/${stories.checkout}?token=${process.env.REACT_APP_STORYBLOK_TOKEN}&timestamp=${Date.now()}`,
  getMembershipStatic: `${STORYBLOK_API_URL}${spaceId}/stories/${stories.membership}?token=${process.env.REACT_APP_STORYBLOK_TOKEN}&timestamp=${Date.now()}`,
  getPartners: `${STORYBLOK_API_URL}${spaceId}/stories/${stories.partners}?token=${process.env.REACT_APP_STORYBLOK_TOKEN}&timestamp=${Date.now()}`,
  getMembershipHomeStudioGuide: `${process.env.REACT_APP_API_URL}client/v1/membership-home-product-type`,
  changeBenefit: `${process.env.REACT_APP_API_URL}client/v1/membership`,
  getBenefits: `${process.env.REACT_APP_API_URL}client/v1/major-benefits`,
  getDeliveryPrice: `${process.env.REACT_APP_API_URL}client/v1/discounts/delivery-price`,
  getMe: `${process.env.REACT_APP_API_URL}client/v1/me`,
  landing: `${STORYBLOK_API_URL}${spaceId}/stories/${stories.landingPage}?token=${process.env.REACT_APP_STORYBLOK_TOKEN}&timestamp=${Date.now()}`,
  landingBenefits: `https://api.storyblok.com/v1/cdn/stories?starts_with=energy-one/benefits&token=${process.env.REACT_APP_STORYBLOK_TOKEN}&timestamp=${Date.now()}`,
  getPricePlan: `${process.env.REACT_APP_API_URL}client/v1/plan-price`,
  generateInvoice: `${process.env.REACT_APP_API_URL}client/v1/generate-invoice`,
  storeUser:  `${process.env.REACT_APP_API_URL}client/v1/store-token`,
  voucherCode: id => `${process.env.REACT_APP_API_URL}client/v1/product/${id}/voucher-code`,
  demand: id => `${process.env.REACT_APP_API_URL}client/v1/product/${id}/demand`,
  getTempalateVariables:  `${STORYBLOK_API_URL}${spaceId}/stories/${stories.templateVariables}?token=${process.env.REACT_APP_STORYBLOK_TOKEN}&timestamp=${Date.now()}`,
  generateGiftInvoice: `${process.env.REACT_APP_API_URL}client/v1/generate-gift-invoice`,
  checkCouponCode: `${process.env.REACT_APP_API_URL}client/v1/check-coupon-code`
}