import * as React from "react";
import { Redirect, Route } from "react-router-dom";
import Spinner from "../components/Spinner";
import { useSelector } from "react-redux";

export const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const isLoggedIn = useSelector((state: any) => state.userAccount.isLoggedIn);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoggedIn === null) {
          return (
            <Spinner id="energy-one-loading-icon" className="spinner-icon" />
          );
        }

        if (isLoggedIn) {
          return <Component {...props} />;
        }

        return (
          <Redirect
            to={{ pathname: "/", state: { from: window.location.hash } }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
