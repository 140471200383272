import React from 'react';
import CodeButton from '../../components/CodeButton';
import SubscribeButton from '../../components/SubscribeButton';
import {  SHOPIFY_ACTION_ID, UPLOADED_DISCOUNT_ACTION_ID } from '../../constants';

const Card = (props: any) => {
const { variables, available_from, product, index } = props;
const {  title, description, price, images, discount_price, product_action_id, id } = product;
  return (
    <div className="eventSelectProduct" id={ "ancMembership" + id }>
      <div className="introImg">
          <img src={images && images[0]} alt="" />
      </div>
        <div className="introBox">
            <div className="introTitle">{title}</div>
            <div className="introSize" dangerouslySetInnerHTML={{__html: description}}>
            </div>
            <div className="introPrice">
              {
                price && (
                  <div className="normalPrice">
                    <span className={discount_price ? "through" : ""}>CHF {price}</span>
                </div>
                )
              }
                {
                  discount_price && (
                    <div className="memeberPrice">
                        One Membership Preis CHF {discount_price}
                    </div>
                  )
                }
               
            </div>
            <div className="btn-area">
              {
                new Date((available_from || "").replace(/-/g, '/')).getTime() >= new Date().getTime() ? (
                <div className="hiddenEventButton">
                  {variables && variables.coming_soon}
                </div>
                ): (
                  product_action_id === SHOPIFY_ACTION_ID || product_action_id === UPLOADED_DISCOUNT_ACTION_ID? (
                    <CodeButton  product={product} />
                  ) : <SubscribeButton product={product} />
                )
              }
            </div>
        </div>
</div>
  );
};

export default Card;