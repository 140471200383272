import React from 'react';
import { connect } from 'react-redux';
import { createVoucherCode } from '../../actionCreators';
import copy from "clipboard-copy";
import { SHOPIFY_ACTION_ID, UPLOADED_DISCOUNT_ACTION_ID } from '../../constants';
import { render } from 'react-dom';
 
const CodeButton = ({product, handleGenerateCode, variables, loadingVouchers }: any) => {
  const { id, product_action_id, coupon_code, product_url } = product;
  const [data, setData] = React.useState({});


  const showCouponCode = (code:string) =>  {
    setData({ [product.id]: true});
    copy(code)
  }

  const renderButton = () => {
    const isLoading = loadingVouchers && loadingVouchers[id];
    if (product.sold){
      return (
          <div className="hiddenEventButton">
            {variables && variables.not_available}
          </div>
      )
    }
    if (product.available_from && new Date(product.available_from.replace(/-/g, '/')).getTime() > new Date().getTime())
      return (
        <div className="hiddenEventButton">
        {variables && variables.coming_soon}
      </div>
    )
    if (product_action_id === SHOPIFY_ACTION_ID || product_action_id === UPLOADED_DISCOUNT_ACTION_ID)
      return <div
              className="rabattBtn"
              onClick={() => isLoading ? null : handleGenerateCode(id)}
              >
                {isLoading ? <img className="loading-spinner" src={require("./loading.svg")} /> : "Rabattcode"}
              </div>
    if (!product_action_id)
      return (
        <div className="hiddenEventButton">
          KEINE TICKETS MEHR
        </div>
      )
  }

  const showCode = data[id]

  return (
    <div>
       {
        showCode && (
          <React.Fragment>
            <span className="copy-label">{variables.product_voucher_copied_code}</span>
            <div className="memberItemButtons d-flex" onClick={() => copy(coupon_code)}>
              <div className="rabattBtnGreen">{coupon_code}</div>
              <div className="itemCopyButton"></div>
            </div>
          </React.Fragment>
          )
        }
        {
          coupon_code && !showCode && (
            <React.Fragment>
              <span className="copy-label">{variables.product_voucher_copy_code}</span>
              <div className="memberItemButtons d-flex" onClick={() => showCouponCode(coupon_code)}>
                <div className="rabattBtnRed">{coupon_code}</div>
                <div className="itemCopyRedButton"></div>
              </div>
            </React.Fragment>
          )
        }
        {
          coupon_code ? (
          <div className="rabattBtn" onClick={() => window.open(product_url)}>{variables.go_to_product}</div>
          ) : renderButton()
        }
    </div>
  )
}

const mapStateToProps = (store:any) => ({
  variables: store.landing.templateVariables,
  loadingVouchers: store.membershipHome.loadingVouchers || {},
})

const mapDispatchToProps = (dispatch: any) => ({
  handleGenerateCode: (id:number) => dispatch(createVoucherCode(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CodeButton);