import {
  GET_PRICE_PLAN_SUCCEED, GENERATE_INVOICE_SUCCEED, GENERATE_INVOICE_FAILED,
  SET_SELECTED_PRODUCT,
  GET_CHECKOUT_STATIC_CONTENT_SUCCEED,
  HIDE_SUCCESSFUL_PAYMENT_MESSAGE,
  GENERATE_GIFT_INVOICE_SUCCEED,
  GENERATE_GIFT_INVOICE_FAILED,
  TOGGLE_GIFT_TOASTR,
  SET_DELIVERY_PRICE
} from '../../actions'


const giftInvoice = (state = {
  showGiftToastr: false,
}, action:any) => {
  switch (action.type) {
    case GENERATE_GIFT_INVOICE_SUCCEED:
        return {
          ...state,
          invoice: action.payload,
          showGiftToastr: true,
        }
    case TOGGLE_GIFT_TOASTR:
        return {
          ...state,
          showGiftToastr: false,
        }
    case GENERATE_GIFT_INVOICE_FAILED:
        return {
          ...state,
          invoice: action.payload,
        }
    case SET_DELIVERY_PRICE:
        return {
          ...state,
          deliveryPrice: action.price,
        }
    default:
      return state
  }
}

export default giftInvoice;