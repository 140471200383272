import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { auth } from '@energych/sdk-auth';
import { storeUser, getLoginStaticContent} from '../../actionCreators';
import { getCookie } from '../../utils';
import getUserInfo from '../../lib/get-user-info';
import getLoginUrl from '../../lib/get-login-url';

class UserAccount extends React.Component<any, any> {

  public componentDidMount() {
    const { getContent, storeData, cb } = this.props;
    getContent();
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  }


  componentDidUpdate() {
    const { userHasActiveMemberShip, history } = this.props;
    if (userHasActiveMemberShip) { 
        history.push("/membership")
    }
    else if (userHasActiveMemberShip === false){
      history.push("/checkout")
    }
  }


    public render() {
      const { history, variables, data } = this.props;
        return (
            <div className="entryAccount">
                <div className="container">
                    <div className="mainArea d-flex">
                        <div className="energyLogin">
                            <div className="energyItemsWrapp">
                              <div className="entryAccountTitle">
                                  {data.content.title}
                                </div>
                                <div className="entryAccountDescz" dangerouslySetInnerHTML={{__html: data.content.body ? data.content.body[0].body.content: ""}} />
                                <div className="selectLogin d-flex">
                                    <div className="loginEnergyAcc">
                                        <div className="loginTitle">
                                            { variables && variables.has_energy_account}
                                            </div>
                                        <a className="account-btn" href={getLoginUrl('/checkout')}>
                                             { variables && variables.login}
                                            </a>
                                    </div>
                                    <div className="loginEnergyAcc">
                                        <div className="loginTitle">
                                        { variables && variables.no_energy_account}
                                            </div>
                                        <a className="account-btn" href={getLoginUrl('/checkout')}>
                                            { variables && variables.register}
                                        </a>
                                    </div>
                                </div>
                                <div className="backToPrev">
                                    <div className="eventBackLink default-btn" onClick={() => history.push("/benefits")}>
                                         { variables && variables.back}
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div className="energyMedia">
                            {
                              data.content.video_url ? (
                                <div className="videoCover" >
                                    <video controls muted loop src={data.content.video_url} className="bg-video" >
                                        <source src={data.content.video_url} type="video/mp4" />
                                    </video>
                                </div>
                              ): (
                                <div className="energyMediaPoster">
                                    <img src={`http:${data.content.image_url}`} alt="" />
                                </div>
                              )
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (store: any) => ({
  data: store.userAccount.data,
  variables: store.landing.templateVariables,
  userHasActiveMemberShip: store.userAccount.userHasActiveMembership,
  cb: store.landing.cb,
  product: store.checkout.product,
});

const mapDispatchToProps = (dispatch:any) => ({
  storeData: (data: any) => dispatch(storeUser(data)),
  getContent: () => dispatch(getLoginStaticContent()),
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserAccount));