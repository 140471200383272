import { put, takeLatest, call } from 'redux-saga/effects'
import { storeUserSucceed, storeUserFailed, getMeSucceed, getMe, getLoginStaticContentSucceed } from '../../actionCreators';
import { makeAsyncCall } from '../../utils';
import { STORE_USER, GET_ME, GET_LOGIN_STATIC_CONTENT } from '../../actions';
import { API } from '../../constants';


function* storeUser(action:any) {
  const { user } = action.payload;
  try {
    const options = {
      url: API.storeUser,
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ user })
    }
    const result = yield call(makeAsyncCall, options)
    if (result.statusCode === 200 || result.statusCode === 201){
      yield put(storeUserSucceed(user));
      yield put(getMe())
    }
    else 
      yield put(storeUserFailed(result.body.data))
  } catch (error) {
    console.log(error);
  }
}


function* getUser() {
  try {
    const options = {
      url: API.getMe,
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(null)
    }
    const result = yield call(makeAsyncCall, options)
    if (result.statusCode === 200 ){
      let hasActiveMembership = false;
      let membership_id;
      let daysLeft = result.body.data.left_days;
      result.body.data.membership.forEach((membership: {is_active: boolean, ordered: string, valid_from: string, valid_to: string, membership_number: number}) => {
        const from = new Date((membership.valid_from || "").replace(/-/g, '/'))
        const to = new Date((membership.valid_to || "").replace(/-/g, '/'))
        const today = new Date();
        const valid = (today >= from && today <=to) ;
        if (membership.is_active && valid){
          hasActiveMembership = true;
          membership_id = membership.membership_number
          return;
        }
      });
      yield put(getMeSucceed(hasActiveMembership, membership_id, daysLeft, result.body.data.product_uses))
    }
  } catch (error) {
    console.log(error);
  }
}



function* getLoginStatic(action:any) {
  try {
    const options = {
      url: API.getLogin,
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
    }
    const result = yield call(makeAsyncCall, options)
    if (result.statusCode === 200 ){
      yield put(getLoginStaticContentSucceed(result.body.story))
    }
  } catch (error) {
    console.log(error);
  }
}


export function* userAccountSaga() {
  yield takeLatest(STORE_USER, storeUser)
  yield takeLatest(GET_ME, getUser)
  yield takeLatest(GET_LOGIN_STATIC_CONTENT, getLoginStatic)
}
