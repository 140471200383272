function processResponse(response:any) {
  const statusCode = response.status;
  const data = response.json();
  return Promise.all([statusCode, data]).then(res => ({
    body: res[1],
    statusCode: res[0],
  }));
}


export function makeAsyncCall(options:any) {
  return fetch(options.url, {
    body: options.body,
    headers: {
      ...options.headers
    },
    method: options.method,
  })
    .then(processResponse);
}

export function getCookie(cname:string) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};