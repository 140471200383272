import * as React from 'react';
import { getLandingPartners } from '../actionCreators';
import { connect } from 'react-redux';

class Events extends React.Component<any, any> {

  componentDidMount() {
    this.props.getPartners()
  }

  renderData() {
    const {data, partnersBlock} = this.props;
    let partners;
    return data.map((d: any, i: number) => {
        if (i === 0) {
            partners =
                <div className="partners">
                    <div className="p-logo d-flex">
                        {
                            partnersBlock.logos.map((p: any)=>(
                                <img src={`https:${p.image}`} className="p-img" width="60" height="60" alt="" key={p._uid} />
                            ))
                        }
                    </div>
                </div>;
        } else {
            partners = <div></div>;
        }
      if ( i % 2 === 0)
        return (
            <div className={`event-row d-flex`} key={i}>
                <div className={`event-img col_40 d-flex left-side`}>
                    <div className="event-thumbnail" style={d.content.image ? {backgroundImage: "url(" + d.content.image + ")"} : {}}>
                        <div className="number-label">{i+1}</div>
                    </div>
                </div>
                <div className="col_60 content-box">
                    <div className="event-title">{d.content.title}</div>
                    <div className="event-description">
                        {d.content.body}
                    </div>
                    {/* {partners}       */}
                </div>
            </div>
        )
      else
        return (
            <div className={`event-row d-flex column-reverse`} key={i}>
                <div className="col_60 content-box">
                    <div className="event-title">{d.content.title}</div>
                    <div className="event-description">
                        {d.content.body}
                    </div>
                </div>
                <div className={`event-img col_40 d-flex right-side column-reverse`}>
                    <div className="event-thumbnail" style={d.content.image ? {backgroundImage: "url(" + d.content.image + ")"} : {}}>
                        <div className="number-label">{i+1}</div>
                    </div>
                </div>
            </div>
        )
    })
  }

    public render() {
        return(
            <div className="events-loop">
                {this.renderData()}
            </div>
        );
    }
}



const mapStateToProps = (store: any) => {
  const { landing } = store;
  return {
    partnersBlock: landing.partnersBlock,
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  getPartners: () => dispatch(getLandingPartners()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Events);